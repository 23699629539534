import React, { useState } from "react";
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Toolbar, Avatar, Typography, createTheme, ThemeProvider } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import BorderAllOutlinedIcon from '@mui/icons-material/BorderAllOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';

const drawerWidth = 220;

const theme = createTheme({
  palette: {
    primary: {
      main: '#CADCFC',
    },
    secondary: {
      main: '#143F6B',
    },
    hover: {
      main: '#EEF5FF',
    },
    click: {
      main: '#0F0F0F',
    }
  },
  shape: {
    borderRadius: 3,
    width: '200px',
    marginleft: '10px',
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: 14,
    fontWeightMedium: 500,
    button: {
      textTransform: 'none',
    },
  },
});

const Sidebar = () => {
  const location = useLocation();
  const [selected, setSelected] = useState(location.pathname);

  const handleClick = (path) => {
    setSelected(path);
  };

  return (
    <ThemeProvider theme={theme}>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { 
            width: drawerWidth, 
            boxSizing: 'border-box',
            backgroundColor: '#074173',
            height: '99%',
            color: '#F0F0F0',
            borderRadius: '20px',
            marginTop: '4px',
            // marginBottom: '34px',
            marginLeft: '4px',
            paddingTop: '10px',
            paddingBottom: '10px',
            // paddingLeft  : '10px',
            paddingRight: '10px',
          },
        }}
      >
        <Toolbar>
        <Link to="/all-leads">
        <Avatar sx={{ width: 174, height: 42, marginTop: 1, borderRadius: '12px' }}>
          <img src="/EnergyBae Logo.jpg" alt="Logo" />
        </Avatar>
      </Link>
        </Toolbar>
        <List>
          <ListItem 
            button 
            component={Link} 
            to="/all-leads" 
            selected={selected === "/all-leads"} 
            onClick={() => handleClick("/all-leads")}
            sx={{ py: 1, px: 1, my: 2, '&.Mui-selected': { backgroundColor: theme.palette.hover.main, '& .MuiTypography-root': { color: 'black' } }, '&:hover': {
              backgroundColor: theme.palette.hover.main, '& .MuiTypography-root': { color: 'black' }
            }, borderRadius: theme.shape.borderRadius, width: theme.shape.width, marginLeft: theme.shape.marginleft }}
          >
            <ListItemIcon>
              <BorderAllOutlinedIcon sx={{ color: selected === "/all-leads" ? theme.palette.secondary.main : theme.palette.primary.main }} />
            </ListItemIcon>
            <ListItemText primary={
              <Typography variant="body1" sx={{ fontWeight: selected === "/all-leads" ? 'bold' : 'regular', fontSize: '15px' }}>
                Leads
              </Typography>
            } />
          </ListItem>
          <ListItem 
            button 
            component={Link} 
            to="/published" 
            selected={selected === "/published"} 
            onClick={() => handleClick("/published")}
            sx={{ py: 1, px: 1, my:2, '&.Mui-selected': { backgroundColor: theme.palette.hover.main, '& .MuiTypography-root': { color: 'black' } }, '&:hover': {
              backgroundColor: theme.palette.hover.main, '& .MuiTypography-root': { color: 'black' }
            }, borderRadius: theme.shape.borderRadius, width: theme.shape.width, marginLeft: theme.shape.marginleft }}
          >
            <ListItemIcon>
              <BallotOutlinedIcon sx={{ color: selected === "/published" ? theme.palette.secondary.main : theme.palette.primary.main }} />
            </ListItemIcon>
            <ListItemText primary={
              <Typography variant="body1" sx={{ fontWeight: selected === "/published" ? 'bold' : 'regular', fontSize: '15px' }}>
                Published Leads
              </Typography>
            } />
          </ListItem>
          <ListItem 
            button 
            component={Link} 
            to="/pending" 
            selected={selected === "/pending"} 
            onClick={() => handleClick("/pending")}
            sx={{ py: 1, px: 1, my: 2, '&.Mui-selected': { backgroundColor: theme.palette.hover.main, '& .MuiTypography-root': { color: 'black' } }, '&:hover': {
              backgroundColor: theme.palette.hover.main, '& .MuiTypography-root': { color: 'black' }
            }, borderRadius: theme.shape.borderRadius, width: theme.shape.width, marginLeft: theme.shape.marginleft }}
          >
            <ListItemIcon>
              <PendingActionsOutlinedIcon sx={{ color: selected === "/pending" ? theme.palette.secondary.main : theme.palette.primary.main }} />
            </ListItemIcon>
            <ListItemText primary={
              <Typography variant="body1" sx={{ fontWeight: selected === "/pending" ? 'bold' : 'regular', fontSize: '15px' }}>
                Pending Leads
              </Typography>
            } />
          </ListItem>
          <ListItem 
            button 
            component={Link} 
            to="/projects" 
            selected={selected === "/projects"} 
            onClick={() => handleClick("/projects")}
            sx={{ py: 1, px: 1, my: 2, '&.Mui-selected': { backgroundColor: theme.palette.hover.main, '& .MuiTypography-root': { color: 'black' } }, '&:hover': {
              backgroundColor: theme.palette.hover.main, '& .MuiTypography-root': { color: 'black' }
            }, borderRadius: theme.shape.borderRadius, width: theme.shape.width, marginLeft: theme.shape.marginleft }}
          >
            <ListItemIcon>
              <TimelineOutlinedIcon sx={{ color: selected === "/projects" ? theme.palette.secondary.main : theme.palette.primary.main }} />
            </ListItemIcon>
            <ListItemText primary={
              <Typography variant="body1" sx={{ fontWeight: selected === "/projects" ? 'bold' : 'regular', fontSize: '15px' }}>
                Project Tracking
              </Typography>
            } />
          </ListItem>
        </List>
      </Drawer>
    </ThemeProvider>
  );
};

export default Sidebar;

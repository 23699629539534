import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMetaValues, getOpenLeads, updateLead } from "../../api";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import CustomTable from "../common/CustomTable";
import { Card, Typography } from "@mui/material";
import { Button, Grid } from '@mui/material';

const Dashboard = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [leads, setLeads] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [owners, setOwners] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedStatus, setSelectedStatus] = useState("OPEN");
  const [selectedPriority, setSelectedPriority] = useState("");


  const itemsPerPage = 100;

  const statusOptions = [
    { label: "CANCELLED", value: "CANCELLED" },
    { label: "IN_PROGRESS", value: "IN_PROGRESS" },
    { label: "OPEN", value: "OPEN" },
    { label: "COMPLETED", value: "COMPLETED" },
    { label: "PUBLISHED", value: "PUBLISHED" },
    { label: "ON_HOLD", value: "ON_HOLD" },
  ];

  const priorityOptions = [
    { label: "Low", value: "Low" },
    { label: "Medium", value: "Medium" },
    { label: "High", value: "High" },
  ];

  const typeOfInstallationOptions = [
    { label: "Industrial", value: "Industrial" },
    { label: "Commercial", value: "Commercial" },
    { label: "Residential Society", value: "Residential Society" },
    { label: "Residential Home", value: "Residential Home" },
  ];

  const columns = [
    // { 
    //   Header: 'Project No', 
    //   accessor: 'projectNo', 
    //   width: '240px' 
    // },
    { 
      Header: 'Customer Name', 
      accessor: 'customerName',
      width: '160px'
    },
    { 
      Header: 'Status', 
      accessor: 'status', 
      editable: true, 
      type: 'select', 
      options: statusOptions
    },
    { 
      Header: 'Priority', 
      accessor: 'priority', 
      editable: true, 
      type: 'select', 
      options: priorityOptions 
    },
    { 
      Header: 'Type Of Installation', 
      accessor: 'typeOfInstallation', 
      editable: true, 
      type: 'select', 
      options: typeOfInstallationOptions,
      width: '160px'
    },
    { 
      Header: 'Product', 
      accessor: 'technology', 
      width: '30px'
    },
    { 
      Header: 'Phone', 
      accessor: 'phoneNumber' 
    },
    { 
      Header: 'Location', 
      accessor: 'location',
      width: '200px'
    },
    { 
      Header: 'Company', 
      accessor: 'company' 
    },
    { 
      Header: 'Account Owner', 
      accessor: 'accountOwner',
    },
    { 
      Header: 'Next Update', 
      accessor: 'nextUpdate' 
    },
    { 
      Header: 'Last Contact', 
      accessor: 'lastContact'
    },
    { 
      Header: 'Added', 
      accessor: 'timestamp' 
    }, 
    { 
      Header: 'Reference', 
      accessor: 'reference', 
      // editable: true, 
      // type: 'select', 
      // options: [] 
    },
  ];

  const handleEdit = (row) => {
    navigate(`/lead/${row.projectId}`);
  };

  const handleOptionSelect = async (lead, option, column) => {
    const accessToken = localStorage.getItem("Authorization");
    let updatedLead = { ...lead };
    let index = leads.findIndex((l) => l.projectId === lead.projectId); // Assuming projectId is a unique identifier

    switch (column) {
      case "reference":
        updatedLead.reference = option;
        break;
      case "typeOfInstallation":
        updatedLead.typeOfInstallation = option;
        break;
      case "priority":
        updatedLead.priority = option;
        break;
      case "accountOwner":
        updatedLead.accountOwner = option;
        break;
      case "status":
        updatedLead.status = option;
        break;
      default:
        console.log("Column not found");
    }

    try {
      const response = await updateLead(
        lead.projectId,
        updatedLead,
        accessToken
      );
      let updatedLeads = [...leads];
      updatedLeads[index] = { ...updatedLead, ...response };
      setLeads(updatedLeads);
    } catch (error) {
      console.error("Failed to update lead", error);
      setError("An error occurred. Please try again later.");
    }
  };

  const getLeads = async (currentPage, selectedStatus) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await getOpenLeads(
        currentPage - 1,
        itemsPerPage,
        accessToken,
        selectedStatus,
      );
      setLeads(response.content);
      setTotalPages(response.totalPages);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again later.");
      }
    }
  };

  useEffect(() => {
    getLeads(currentPage, selectedStatus);
  }, [currentPage, selectedStatus]);

  useEffect(() => {
    const getOwners = async () => {
      const accessToken = localStorage.getItem("Authorization");
      try {
        const response = await getMetaValues("account_owner", accessToken);
        setOwners(response);
      } catch (error) {
        console.log("Failed to load owners");
      }
    };
    getOwners();
  }, []);

  const buttons = [
    {
      label: "New",
      onClick: () => {navigate("/create-lead");},
      sx: { backgroundColor: "#074173", color: "white", width: '90px' },
    },
    {
      label: "Bulk Upload",
      onClick: () => {console.log("Bulk upload clicked");},
      sx: { ml: 1, mr: 1, backgroundColor: "#074173", color: "white", width: '137px' },
    },
  ];

  const handleFilterChange = (accessor, value) => {
    if (accessor === "status") {
      setSelectedStatus(value);
    }
    if (accessor === "priority") {
      setSelectedPriority(value);
    }
  };

  const filters = [
    {
      label: "Status",
      accessor: "status",
      options: [
        { value: "OPEN", label: "Open" },
        { value: "PUBLISHED", label: "Published" },
      ],
      value: selectedStatus,
      sx: { width: '130px' },

    },
    {
      label: "Priority",
      accessor: "priority",
      options: [
        { value: "High", label: "High" },
        { value: "Medium", label: "Medium" },
        { value: "Low", label: "Low" },
      ],
      value: selectedPriority,
      sx: { ml: 1, mr: 1, width: '130px' },

    }
  ];

  return (
    <div className="flex h-full">
      <Header />
      <Sidebar />
      <main className="flex-grow p-11 mt-9 overflow-auto">
        <Card>      
          <CustomTable
            columns={columns}
            data={leads}
            onEdit={handleEdit}
            onOptionSelect={handleOptionSelect}
            editable={true}
            buttons={buttons}
            filters={filters}
            onFilterChange={handleFilterChange}
          />
        </Card>
      </main>
    </div>
  );
};

export default Dashboard;


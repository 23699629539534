import React from "react";
import Chat from "./Chat";

const ChatModal = ({ setModal, projectNo }) => {
  return (
    <div className="fixed bottom-0 right-10 z-50 w-96 h-3/5 mb-24 rounded-t-2xl shadow-2xl">
      <div className="flex w-full justify-between items-center p-4 bg-white rounded-t-2xl shadow-2xl">
        <div className="flex items-center">
          <img
            src="/icon-logo.png"
            alt="User"
            className="w-10 h-10 rounded-full mr-2"
          />
          <h1 className="text-xl">Chat</h1>
        </div>
        <img
          src="/close-nobox.png"
          alt="Close"
          className="cursor-pointer w-6"
          onClick={() => {
            setModal(false);
          }}
        />
      </div>
      <div className="h-full overflow-y-auto rounded-b-2xl shadow-2xl">
        <Chat projectNo={projectNo} />
      </div>
    </div>
  );
};

export default ChatModal;

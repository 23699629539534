import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import { Card, Typography } from "@mui/material";
import CustomTable from "../common/CustomTable";
import PeopleIcon from '@mui/icons-material/People';


const Dashboard = () => {
  const [data, setData] = useState([]); 
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const getVendors = async () => {
      try {
        const accessToken = localStorage.getItem("Authorization");
        const response = await axios(
          `${process.env.REACT_APP_SERVER}/admin/vendors?page=${
            currentPage - 1
          }&size=${itemsPerPage}`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          }
        );

        if (response.status === 200) {
          setData(response.data.content);
          console.log('response.data.content', response.data.content);

        } else {
          console.log("Error: ");
        }
      } catch (error) {
        console.log("Error: ");
      }
    };
    getVendors();
  }, [currentPage]);

  // const handleLogout = () => {
  //   localStorage.removeItem("Authorization");
  //   navigate("/login");
  // };

  const Columns = [
    { Header: "First Name", accessor: "firstname" },
    { Header: "Last Name", accessor: "lastname" },
    { Header: "Number", accessor: "mobileNumber" },
    { Header: "Email", accessor: "email" },
    { Header: "Company Name", accessor: "companyName" },
    { Header: "GST No.", accessor: "gstNo" },
    { Header: "Owner Name", accessor: "ownerName" },
    { Header: "Owner Designation", accessor: "designation" },
    { Header: "Address 1", accessor: "address" },
    { Header: "City", accessor: "city" },
    { Header: "Pin Code", accessor: "pincode" },
    { Header: "Company's Type", accessor: "companyType" },
    { Header: "Preferred Area of Operation", accessor: "areaOfOperation" },
    { Header: "Range of Operation", accessor: "rangeOfOperation" },
    { Header: "Services Provided", accessor: "serviceProvided" },
    { Header: "Expertise", accessor: "expertise" },
    { Header: "Subsidy", accessor: "subsidy" },
    { Header: "Workshop", accessor: "workshop" },
];

  return (
    <div className="flex h-full">
    <Header />
    <Sidebar />

    <main className="flex-grow p-11 mt-9 overflow-auto">
      <Card>
      <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#074173' }}>
        <PeopleIcon sx={{ mr: 2 }} />
          All Vendors
        </Typography>
      <CustomTable columns={Columns} data={data}/>
      </Card>
    </main>
  </div>
  );
};

export default Dashboard;

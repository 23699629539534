import React from "react";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";

const Customer = () => {
  return (
    <div>
      <Header />

      <div className="flex flex-row">
        <div >
          <Sidebar />
        </div>

        <div className="grid grid-cols-3 gap-x-32 m-20">
          <div>
            <label
              htmlFor="lastContact"
              className="block text-lg font-medium text-gray-700"
            >
              Last Contact
            </label>
            <input
              type="date"
              id="lastContact"
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="added"
              className="block text-lg font-medium text-gray-700"
            >
              Added
            </label>
            <input
              type="text"
              id="added"
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="accountOwner"
              className="block text-lg font-medium text-gray-700"
            >
              Account Owner
            </label>
            <input
              type="text"
              id="accountOwner"
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="status"
              className="block text-lg font-medium text-gray-700"
            >
              Status
            </label>
            <input
              type="text"
              id="status"
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="priority"
              className="block text-lg font-medium text-gray-700"
            >
              Priority
            </label>
            <input
              type="text"
              id="priority"
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="phone"
              className="block text-lg font-medium text-gray-700"
            >
              Phone
            </label>
            <input
              type="text"
              id="phone"
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="number"
              className="block text-lg font-medium text-gray-700"
            >
              Number
            </label>
            <input
              type="text"
              id="number"
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="location"
              className="block text-lg font-medium text-gray-700"
            >
              Location
            </label>
            <input
              type="text"
              id="location"
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="references"
              className="block text-lg font-medium text-gray-700"
            >
              References
            </label>
            <input
              type="text"
              id="references"
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="email"
              className="block text-lg font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="text"
              id="email"
              className="mt-1 block w-full rounded-md border-gray-300 border-2 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div>
            <label
              htmlFor="nextUpdate"
              className="block text-lg font-medium text-gray-700"
            >
              Next Update
            </label>
            <input
              type="date"
              id="nextUpdate"
              className="mt-1 block w-full rounded-md border-2 border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          type="button"
          class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default Customer;

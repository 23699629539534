import React from "react";
import { AppBar, Toolbar, IconButton, Box } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import { NavLink, useNavigate } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';

const Header = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("Authorization");
    navigate("/login");
  };

  return (
    <AppBar position="fixed" sx={{ backgroundColor: '#EEF5FF', borderRadius: '20px' }}>
      <Toolbar>
        <nav style={{ marginLeft: 'auto' }}>          
          <ul className="flex gap-11">
            <li>
              <NavLink 
                to="/all-leads" 
                className={({ isActive }) => 
                  isActive ? 'text-blue-600 hover:text-customText' : 'text-customText hover:text-blue-600'
                }
              >
                <HomeIcon className="mb-1 mr-1" fontSize="small" />
                Home
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/vendors" 
                className={({ isActive }) => 
                  isActive ? 'text-blue-600 hover:text-customText' : 'text-customText hover:text-blue-600'
                }
              >
                All Vendors
              </NavLink>
            </li>
            <li>
              <NavLink 
                to="/metadata" 
                className={({ isActive }) => 
                  isActive ? 'text-blue-600 hover:text-customText' : 'text-customText hover:text-blue-600'
                }
              >
                Metadata
              </NavLink>
            </li>
          </ul>
        </nav>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, color: 'black' }}> 
          <IconButton color="inherit" onClick={handleLogout} sx={{ marginLeft: 7 }}>
            <LogoutIcon />
          </IconButton>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

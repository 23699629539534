import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { useFormik } from "formik";
import { State, City } from "country-state-city";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ChatModal from "../common/ChatModal";
import Header from "./Header"
import PermMediaIcon from '@mui/icons-material/PermMedia';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import {
  IconButton,
  Box,
  TextField,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Card,
  Typography,
  Grid,
  Modal
} from '@mui/material';
import {
  SiteCondition,
  Solar,
  Wind,
  MeterDetails,
  Inverter,
  Battery,
} from "./Feilds";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getLeadById,
  uploadedProposals,
  updateProposalStatus,
  getProjectFile,
  uploadedAttachments,
  getMetaValues,
} from "../../api";
import { toast } from "react-toastify";

const LeadFormPending = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const vendorId = searchParams.get("vendorId");
  const projectNo = searchParams.get("projectNo");
  const { projectId } = useParams();
  const [modal, setModal] = useState(null);
  const [proposals, setProposals] = useState([]);
  const [error, setError] = useState(null);
  const leadId = searchParams.get("leadId");
  const [attachments, setAttachments] = useState([]);
  const [techOptions, setTechOptions] = useState([]);
  const [referenceOptions, setReferenceOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const [requirementForm, setRequirementForm] = useState({
    typeOfInstallation: "",
    location: "",
    pincode: "",
    //site condition
    avgBill: 0,
    siteCoordinates: "",
    numberOfFloors: 0,
    totalFlats: 0,
    roofTopArea: "",
    siteDescription: "",
    // solar
    solarCapacity: 0,
    solarBrand: "",
    solarType: "",
    // wind
    windCapacity: 0,
    windBrand: "",
    windType: "",
    // meter
    meterType: "",
    numberOfMeter: 0,
    consumerNumber: "",
    consumerType: "",
    sanctionedLoad: "",
    contractDemand: "",
    // inverter
    inverterCapacity: 0,
    connectionType: "",
    batteryBackupCapacity: "",
    batteryCapacity: "",
    batteryType: "",
    numberOfBatteries: "",
    systemVoltage: "",
    backupPower: "",
    // power
    projectStatus: "",
    product: "",
    accountOwner: "",
  });

  const handleRequirementChange = (event) => {
    const { name, value } = event.target;
    setRequirementForm((prev) => ({ ...prev, [name]: value }));
  };

  const approveProposal = async (key) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await updateProposalStatus(
        key,
        requirementForm.projectNo,
        leadId,
        accessToken
      );
      console.log(response);
      setError(null);
      navigate("/projects");
      toast.success("Proposal Approved Successfully", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again later.");
      }
    }
  };

  const fetchOptions = async (type, setter) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const res = await getMetaValues(type, accessToken);
      setter(res);
    } catch (error) {
      console.log("Failed to load metadata", error);
    }
  };

  const getTechnologyText = (value) => {
    const options = {
      "Solar on-grid": "solar-on-grid",
      "Solar off-grid": "solar-off-grid",
      "Wind on-grid": "wind-on-grid",
      "Wind off-grid": "wind-off-grid",
      "S&W off-grid": "sw-off-grid",
      "S&W on-grid": "sw-on-grid",
      "Hybrid(ON-OFF grid) solar": "hybrid-solar-on-off-grid",
      "Hybrid(ON-OFF grid) wind": "hybrid_wind_on_off_grid",
      "Hybrid(ON-OFF grid) Wind+Solar": "hybrid_wind_solar_on_off_grid",
    };

    return options[value] || "Select Technology";
  };

  useEffect(() => {
    fetchOptions("technology", setTechOptions);
    fetchOptions("type_of_installation", setReferenceOptions);
  }, []);

  const handleImageClick = async (imageUrl) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await getProjectFile(imageUrl, accessToken);
      window.open(response, "_blank");
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Error uploading file");
    }
  };

  const isVideoFile = (key) => {
    const videoExtensions = ['mp4', 'mov', 'avi', 'wmv', 'flv'];
    const extension = key.split('.').pop();
    return videoExtensions.includes(extension);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchAttachments = async (projectNo) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await uploadedAttachments(projectNo, accessToken);
      const files = response.data.files;
      setAttachments(files);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again later.");
      }
    }
  };

  useEffect(() => {
    const getLead = async () => {
      const accessToken = localStorage.getItem("Authorization");
      try {
        const response = await getLeadById(projectId, accessToken);
        console.log(response);
        setRequirementForm(response);
        fetchAttachments(response.projectNo);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message);
        } else {
          setError("An error occurred. Please try again later.");
        }
      }
    };
    getLead();
  }, []);

  useEffect(() => {
    const fetchUploadedProposals = async () => {
      const accessToken = localStorage.getItem("Authorization");
      //console.log("Project No in Uploaded Projects",projectNo);
      try {
        const response = await uploadedProposals(
          vendorId,
          projectNo,
          accessToken
        );
        console.log(response);
        setProposals(response);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message);
        } else {
          setError("An error occurred. Please try again later.");
        }
      }
    };

    fetchUploadedProposals();
  }, []);

  const renderComponents = () => {
    switch (requirementForm.technology) {
      case "solar-on-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "solar-off-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "wind-on-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "wind-off-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "sw-off-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "sw-on-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "hybrid-solar-on-off-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "hybrid_wind_on_off_grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "hybrid_wind_solar_on_off_grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              handleReq
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
              uirementChange
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      default:
        return null;
    }
  };

  // const columns = [
  //   { Header: 'Project No', accessor: 'projectNo' },
  //   { Header: 'Customer Name', accessor: 'customerName' },
  //   { Header: 'Vendor Name', accessor: 'vendorName' },
  //   { Header: 'Product', accessor: 'technology' },
  //   { Header: 'Location', accessor: 'location' },
  //   { Header: 'Status', accessor: 'leadStatus' },
  // ];

  const viewProposal = async (key) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await getProjectFile(key, accessToken);
      window.open(response, "_blank");
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Error uploading file");
    }
  };

  return (
    <div className="flex">   
      <div>
      <Header />
      <Sidebar />
      </div>
      <div className="mt-20">
      <Box sx={{ ml: 3, mr: 3 }}>
      <Box sx={{ ml: 3, mr: 3, p: 2 }}>
        <Card sx={{ p: 3 }}>
        <Typography variant="h5" component="div" className="m-2">
          Requirement
        </Typography>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="typeOfInstallation">Type of Installation</InputLabel>
              <Select
                id="typeOfInstallation"
                name="typeOfInstallation"
                value={requirementForm?.typeOfInstallation ?? ""}
                onChange={handleRequirementChange}
                placeholder="Residential / Society etc."
              >
                <MenuItem value="">None</MenuItem>
                {referenceOptions.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="location"
              name="location"
              label="Location"
              value={requirementForm?.location ?? ""}
              onChange={handleRequirementChange}
              placeholder="Enter location"
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="pincode"
              name="pincode"
              label="Pincode"
              value={requirementForm?.pincode ?? ""}
              onChange={handleRequirementChange}
              placeholder="Enter pincode"
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="priority">Priority</InputLabel>
              <Select
                id="priority"
                name="priority"
                value={requirementForm.priority ?? ""}
                onChange={handleRequirementChange}
              >
                <MenuItem value="">None</MenuItem>
                <MenuItem value="high">High</MenuItem>
                <MenuItem value="medium">Medium</MenuItem>
                <MenuItem value="low">Low</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" gutterBottom>
              Attachments
            </Typography>
            <Box>
              <IconButton
                onClick={handleOpen}
                className="cursor-pointer"
                sx={{ marginRight: '8px', marginBottom: '8px', width: '64px', height: '64px' }}
              >
              <PermMediaIcon sx={{ width: '100%', height: '100%' }}/>
              </IconButton>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="attachments-modal-title"
                aria-describedby="attachments-modal-description"
              >
            <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 3,
                borderRadius: 1,
              }}
            >
              <Typography variant="subtitle1" gutterBottom sx={{ textAlign: 'left' }}>
                Find Attachments
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', mt: 2 }}>
                <Grid container spacing={2} justifyContent="center">
                  {attachments.map(({ key }, index) => {
                    const isVideo = isVideoFile(key);
                    return (
                      <Grid item key={index}>
                        <IconButton
                          onClick={() => handleImageClick(key)}
                          sx={{
                            padding: '10px',
                            width: '84px',
                            height: '84px',
                            position: 'relative',
                            borderRadius: 0
                          }}
                        >
                          <img
                            src={`https://energybae-bucket.s3.ap-south-1.amazonaws.com/${key}`}
                            alt={isVideo ? "" : `Thumbnail ${index + 1}`}
                            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }}
                          />
                          {isVideo && (
                            <PlayCircleIcon
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                width: '74px',
                                height: '74px',
                                transform: 'translate(-50%, -50%)',
                                color: '#EEF5FF',
                                fontSize: 70,
                                backgroundColor: '#BED7DC',
                                borderRadius: '10px'
                              }}
                            />
                          )}
                        </IconButton>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
            </Modal>
            </Box>
          </Grid>
          </Grid>
      </Card>

      <Card sx={{ p: 3, mt: 1 }}>
          <Typography variant="h5" component="div" className="m-2">
                Technology
              </Typography>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="technology">Technology</InputLabel>
                <Select
                  id="technology"
                  name="technology"
                  value={requirementForm.technology ?? ""}
                  onChange={handleRequirementChange}
                  >
                  <MenuItem value={null}>Select Technology</MenuItem>
                  {techOptions.map((item, index) => (
                      <option key={index} value={getTechnologyText(item)}>
                        {item}
                      </option>
                    ))}
                </Select>
              </FormControl>
              </Grid>
              </Grid>
              {renderComponents()}
        </Card>

      <Card sx={{ p: 3, mt: 2 }}>
      <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
      <Typography variant="h5" component="div" className="m-2">
          Site visit
        </Typography>
            <TextField
            variant="outlined"
            margin="normal"
            placeholder="Requested Time Slot"
          />
          </Grid>
          </Grid>
        </Card>
            
      <Card sx={{ p: 3, mt: 2 }}>
        <Typography variant="h5" component="div" className="m-2">
          Uploaded Proposals
        </Typography>
            <TableContainer 
              className="mx-auto"
              component={Paper}
            >
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Sr. No.</TableCell>
                    <TableCell>File Name</TableCell>
                    <TableCell>Upload Date</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {proposals?.map((row, index) => (
                    <TableRow key={row.id}>
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell>{row.key}</TableCell>
                      <TableCell>{row.addedOn}</TableCell>
                      <TableCell>{row.timestamp}</TableCell>
                      <TableCell>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            viewProposal(row.key);
                          }}
                          className="bg-blue-500  text-white py-2 px-4 rounded-lg w-fit mr-2"
                        >
                          View
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            approveProposal(row.key);
                          }}
                          className="bg-blue-500  text-white py-2 px-4 rounded-lg w-fit"
                        >
                          Approve
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
        {/* <CustomTable columns={columns} data={proposals} onEdit={handleEdit} editable={true} /> */}
      </Card>
     

     
      {modal ? (
        <ChatModal projectNo={leadId} setModal={setModal} />
      ) : (
        <div className="fixed bottom-10 right-10 z-50 rounded-full shadow-lg">
          <button
            onClick={() => {
              setModal(true);
            }}
            className="w-16 h-16 rounded-full bg-gray-800 text-white border-none cursor-pointer flex justify-center items-center"
          >
            <img src="/chat.png" alt="chat" className="w-8 h-8" />
          </button>
        </div>
      )}
        </Box>
   </Box>
    </div>
    </div>
  );
};
export default LeadFormPending;

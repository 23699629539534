import React, { useEffect, useRef, useState } from "react";
import Message from "./Message";
import { getChats, sendMessage } from "../../api";

const Chat = ({ projectNo }) => {
  const [newMessage, setNewMessage] = useState("");
  const chatContainerRef = useRef(null);

  const [chats, setChats] = useState([]);

  const vendoChats = async () => {
    const accessToken = localStorage.getItem("Authorization");

    try {
      const response = await getChats(projectNo, accessToken);
      setChats(response);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        console.log(error.response.data.message);
      } else {
        console.log("An error occurred. Please try again later.");
      }
    }
  };

  useEffect(() => {
    vendoChats();
  }, []);

  const sendVendorMessage = async () => {
    const accessToken = localStorage.getItem("Authorization");
    if (newMessage.trim() === "") return;
    const message = {
      message: newMessage,
      entity: "ADMIN",
      leadId: projectNo,
    };

    try {
      const response = await sendMessage(message, accessToken);
      console.log(response);
      setNewMessage("");
      vendoChats();
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        console.log(error.response.data.message);
      } else {
        console.log("An error occurred. Please try again later.");
      }
    }
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  }, [chats]);

  const groupedChats = chats.reduce((acc, chat) => {
    const date = chat.date;
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(chat);
    return acc;
  }, {});

  return (
    <div
      className="flex flex-col w-full h-full rounded-b-2xl shadow-2xl"
      style={{ overflowY: "auto" }}
    >
      <div
        ref={chatContainerRef}
        className="overflow-y-auto bg-gray-200 flex-1 p-4 "
      >
        {Object.entries(groupedChats).map(([date, chatsForDate]) => (
          <div key={date}>
            {/* <div className="w-full text-base text-center text-gray-500 mb-2">
              {date}
            </div> */}
            {chatsForDate.map((chat, index) => (
              <Message
                key={index}
                user={chat}
                message={chat.message}
                time={chat.time}
                date={chat.date}
              />
            ))}
          </div>
        ))}
      </div>
      <div className="w-full mx-auto flex bg-white ">
        <input
          type="text"
          className="flex-grow px-4 py-1 m-2 h-10 text-base outline-none"
          placeholder="Type a message..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          style={{ flexBasis: "50%" }}
        />
        <button
          onClick={sendVendorMessage}
          className="bg-transparent text-white py-1 px-4 rounded-md hover:bg-white hover:text-primary text-md m-2 flex-grow-0"
        >
          <img src="/send.png" alt="send" className="w-5 h-5" />
        </button>
      </div>
    </div>
  );
};

export default Chat;

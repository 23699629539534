import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { useFormik } from "formik";
import { State, City } from "country-state-city";
import { Stack, Grid, TextField, FormControl, InputLabel, Select, MenuItem, Card, Typography, Box, Button, IconButton, Modal } from "@mui/material";
import CustomTable from "../common/CustomTable";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import {
  SiteCondition,
  Solar,
  Wind,
  MeterDetails,
  Inverter,
  Battery,
} from "./Feilds";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateLead,
  deleteFromStorage,
  getLeadById,
  uploadToStorage,
  getAssignedVendors,
  getProjectFile,
  uploadedAttachments,
  getMetaValues,
} from "../../api";
import Header from "./Header";

const LeadFormPublished = () => {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [vendors, setVendors] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [techOptions, setTechOptions] = useState([]);
  const [referenceOptions, setReferenceOptions] = useState([]);

  const [cities, setCities] = useState([]);
  const [leadId, setLeadId] = useState(null);

  const [open, setOpen] = useState(false);

  const [projectNo, setProjectNo] = useState('');

  const addressFormik = useFormik({
    initialValues: {
      country: null,
      state: null,
      city: null,
    },
    onSubmit: (values) => console.log(JSON.stringify(values)),
  });
  const { values, handleSubmit } = addressFormik;

  const [requirementForm, setRequirementForm] = useState({
    typeOfInstallation: "",
    location: "",
    pincode: "",
    //site condition
    avgBill: 0,
    siteCoordinates: "",
    numberOfFloors: 0,
    totalFlats: 0,
    roofTopArea: "",
    siteDescription: "",
    // solar
    solarCapacity: 0,
    solarBrand: "",
    solarType: "",
    // wind
    windCapacity: 0,
    windBrand: "",
    windType: "",
    // meter
    meterType: "",
    numberOfMeter: 0,
    consumerNumber: "",
    consumerType: "",
    sanctionedLoad: "",
    contractDemand: "",
    // inverter
    inverterCapacity: 0,
    connectionType: "",
    batteryBackupCapacity: "",
    batteryCapacity: "",
    batteryType: "",
    numberOfBatteries: "",
    systemVoltage: "",
    backupPower: "",
    // power
    projectStatus: "",
    product: "",
    accountOwner: "",
  });

  const handleRequirementChange = (event) => {
    const { name, value } = event.target;
    setRequirementForm((prev) => ({ ...prev, [name]: value }));
  };

  const updateRequirements = async () => {
    const accessToken = localStorage.getItem("Authorization");

    try {
      const response = await updateLead(
        requirementForm.projectId,
        requirementForm,
        accessToken
      );
      localStorage.removeItem("uploadedFiles");
      setUploadedFiles(null);
      navigate("/published");
    } catch (error) {
      console.error("Failed to update lead", error);
      setError("An error occurred. Please try again later.");
    }
  };

  const fetchOptions = async (type, setter) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const res = await getMetaValues(type, accessToken);
      setter(res);
    } catch (error) {
      console.log("Failed to load metadata", error);
    }
  };

  useEffect(() => {
    fetchOptions("technology", setTechOptions);
    fetchOptions("type_of_installation", setReferenceOptions);
  }, []);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleUpload(file, requirementForm.projectNo);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const file = droppedFiles[0];
      handleUpload(file);
    }
  };

  const handleUpload = async (file, projectNo) => {
    const accessToken = localStorage.getItem("Authorization");
    if (!projectNo) {
      setError("Please create a project");
      return;
    }

    setUploading(true);

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "site-media");
      formData.append("project_no", projectNo);

      const response = await uploadToStorage(formData, accessToken);
      if (localStorage.getItem("uploadedFiles")) {
        const uploadedFiles = JSON.parse(localStorage.getItem("uploadedFiles"));
        uploadedFiles.push(response.data.filename);
        localStorage.setItem("uploadedFiles", JSON.stringify(uploadedFiles));
        setUploadedFiles(uploadedFiles);
      } else {
        const uploadedFiles = [];
        uploadedFiles.push(response.data.filename);
        localStorage.setItem("uploadedFiles", JSON.stringify(uploadedFiles));
        setUploadedFiles(uploadedFiles);
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Error uploading file");
    }

    setUploading(false);
  };

  const handleRemoveItem = async (indexToRemove) => {
    const accessToken = localStorage.getItem("Authorization");
    const response = await deleteFromStorage(
      uploadedFiles[indexToRemove],
      accessToken
    );

    const newUploadedFiles = uploadedFiles.filter(
      (_, index) => index !== indexToRemove
    );

    setUploadedFiles(newUploadedFiles);
    localStorage.setItem("uploadedFiles", JSON.stringify(newUploadedFiles));
  };

  useEffect(() => {
    const uploadedFilesString = localStorage.getItem("uploadedFiles");
    if (uploadedFilesString) {
      const uploadedFilesArray = JSON.parse(uploadedFilesString);
      setUploadedFiles(uploadedFilesArray);
    }
  }, []);

  useEffect(() => {
    if (values.state) {
      const updatedCities = City.getCitiesOfState(
        values.country.isoCode,
        values.state.isoCode
      ).map((city) => ({
        label: city.name,
        value: city.isoCode,
        ...city,
      }));
      setCities(updatedCities);
    }
  }, [values.state]);

  const handleImageClick = async (imageUrl) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await getProjectFile(imageUrl, accessToken);
      window.open(response, "_blank");
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Error uploading file");
    }
  };

  const isVideoFile = (key) => {
    const videoExtensions = ['mp4', 'mov', 'avi', 'wmv', 'flv'];
    const extension = key.split('.').pop();
    return videoExtensions.includes(extension);
  };

  const isPDFFile = (key) => {
    const pdfExtensions = ['pdf'];
    const extension = key.split('.').pop();
    return pdfExtensions.includes(extension);
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchAttachments = async (projectNo) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await uploadedAttachments(projectNo, accessToken);
      const files = response.data.files;
      setAttachments(files);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again later.");
      }
    }
  };

  const getTechnologyText = (value) => {
    const options = {
      "Solar on-grid": "solar-on-grid",
      "Solar off-grid": "solar-off-grid",
      "Wind on-grid": "wind-on-grid",
      "Wind off-grid": "wind-off-grid",
      "S&W off-grid": "sw-off-grid",
      "S&W on-grid": "sw-on-grid",
      "Hybrid(ON-OFF grid) solar": "hybrid-solar-on-off-grid",
      "Hybrid(ON-OFF grid) wind": "hybrid_wind_on_off_grid",
      "Hybrid(ON-OFF grid) Wind+Solar": "hybrid_wind_solar_on_off_grid",
    };

    return options[value] || "Select Technology";
  };


  useEffect(() => {
    const getLead = async () => {
      const accessToken = localStorage.getItem("Authorization");
      try {
        const response = await getLeadById(projectId, accessToken);
        setRequirementForm(response);
        setLeadId(response.projectId);
        fetchAttachments(response.projectNo);
        setProjectNo(response.projectNo);

      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message);
        } else {
          setError("An error occurred. Please try again later.");
        }
      }
    };
    getLead();
  }, []);


  const fetchAssignedVendors = async (projectNo) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await getAssignedVendors(projectNo, accessToken);
      console.log("response",response)
      setVendors(response.data.vendors);

    } catch (error) {
      console.error("Error fetching vendors: ", error);
      setError(
        "An error occurred while fetching vendors. Please try again later."
      );
    }
  };

  useEffect(() => {
    if (projectNo) {
      fetchAssignedVendors(projectNo);
    }
  }, [projectNo])

  const renderComponents = () => {
    switch (requirementForm.technology) {
      case "solar-on-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "solar-off-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "wind-on-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "wind-off-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "sw-off-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "sw-on-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "hybrid-solar-on-off-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "hybrid_wind_on_off_grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "hybrid_wind_solar_on_off_grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              handleReq
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
              uirementChange
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      default:
        return null;
    }
  };

  const columns = [
    { Header: 'Company', accessor: 'companyName' },
    { Header: 'City', accessor: 'city' },
    { Header: 'Address', accessor: 'address' },
    { Header: 'Pincode', accessor: 'pincode' }

  ];

  return (
    <div>
      <div className="flex">
        <div >
          <Header />
          <Sidebar />
        </div>
      <div className="mt-20">
    <Box sx={{ ml: 3, mr: 3 }}>
    <Box sx={{ ml: 3, mr: 3, p: 2 }}>
      {/* <form onSubmit={handleSubmit}> */}
        <Card sx={{ p: 3 }}>
          <Stack spacing={2}>
            <Typography variant="h6">Requirement</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="installation">Type of Installation</InputLabel>
                  <Select
                    id="installation"
                    name="typeOfInstallation"
                    value={requirementForm?.typeOfInstallation ?? ""}
                    onChange={handleRequirementChange}
                    placeholder="Residential / Society etc."
                    label="Type of Installation"
                  >
                    <MenuItem value={null}>None</MenuItem>
                    {referenceOptions.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="location"
                  name="location"
                  label="Location"
                  value={requirementForm?.location ?? ""}
                  onChange={handleRequirementChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="pincode"
                  name="pincode"
                  label="Pincode"
                  value={requirementForm?.pincode ?? ""}
                  onChange={handleRequirementChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="priority">Priority</InputLabel>
                  <Select
                    id="priority"
                    name="priority"
                    value={requirementForm.priority ?? ""}
                    onChange={handleRequirementChange}
                    label="Priority"
                  >
                    <MenuItem value="none">None</MenuItem>
                    <MenuItem value="high">High</MenuItem>
                    <MenuItem value="medium">Medium</MenuItem>
                    <MenuItem value="low">Low</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" gutterBottom>
              Attachments
            </Typography>
            <Box>
              <IconButton
                onClick={handleOpen}
                className="cursor-pointer"
                sx={{ marginRight: '8px', marginBottom: '8px', width: '64px', height: '64px' }}
              >
              <PermMediaIcon sx={{ width: '100%', height: '100%' }}/>
              </IconButton>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="attachments-modal-title"
                aria-describedby="attachments-modal-description"
              >
            <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 3,
                borderRadius: 1,
              }}
            >
              <Typography variant="subtitle1" gutterBottom sx={{ textAlign: 'left' }}>
                Find Attachments
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', mt: 2 }}>
                <Grid container spacing={2} justifyContent="center">
                  {attachments.map(({ key }, index) => {
                    const isVideo = isVideoFile(key);
                    const isPDF = isPDFFile(key);
                    return (
                      <Grid item key={index}>
                        <IconButton
                          onClick={() => handleImageClick(key)}
                          sx={{
                            padding: '10px',
                            width: '84px',
                            height: '84px',
                            position: 'relative',
                            borderRadius: 0
                          }}
                        >
                          <img
                            src={`https://energybae-bucket.s3.ap-south-1.amazonaws.com/${key}`}
                            alt={isVideo || isPDF ? "" : `Thumbnail ${index + 1}`}
                            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }}
                          />
                          {isVideo && (
                            <PlayCircleIcon
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                width: '70px',
                                height: '70px',
                                transform: 'translate(-50%, -50%)',
                                color: '#EEF5FF',
                                fontSize: 70,
                                backgroundColor: '#BED7DC',
                                borderRadius: '10px'
                              }}
                            />
                          )}
                           {isPDF && (
                            <PictureAsPdfIcon
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                width: '70px',
                                height: '70px',
                                transform: 'translate(-50%, -50%)',
                                color: '#EEF5FF',
                                fontSize: 70,
                                backgroundColor: '#BED7DC',
                                borderRadius: '10px'
                              }}
                            />
                          )}
                        </IconButton>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
            </Modal>
            </Box>
          </Grid>
          </Stack>
        </Card>
      {/* </form> */}
      
        <Card sx={{ p: 3, mt: 1 }}>
          <Typography variant="h5" component="div" className="m-2">
                Technology
              </Typography>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="technology">Technology</InputLabel>
                <Select
                  id="technology"
                  name="technology"
                  value={requirementForm?.technology ?? ""}
                  onChange={handleRequirementChange}
                  label="Technology"
                  >
                  <MenuItem value={null}>Select Technology</MenuItem>
                  {techOptions.map((item, index) => (
                      <option key={index} value={getTechnologyText(item)}>
                        {item}
                      </option>
                    ))}
                </Select>
              </FormControl>
              </Grid>
              </Grid>
              {renderComponents()}
        </Card>
            <Card sx={{ p: 3, mt: 2 }}>
              <Typography variant="h5" component="div" className="m-4">
                Attach Site Images and Videos
              </Typography>

                  <div className="flex flex-col items-center mt-6">
                    <div
                      className="border-2 border-dashed rounded-lg bg-gray-100 border-gray-400 p-10 text-center flex flex-col items-center w-4/5"
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                    >
                      <p className="text-lg">
                        {uploading ? "Uploading..." : "Drag and drop file here"}
                      </p>

                      <input
                        id="fileInput"
                        type="file"
                        onChange={handleFileChange}
                        multiple
                        className="hidden"
                      />
                      {!uploading && (
                        // <button
                        //   onClick={() =>
                        //     document.getElementById("fileInput").click()
                        //   }
                        //   className="bg-slate-400 text-white rounded-lg p-1"
                        // >
                        //   Upload
                        // </button>
                        <Button
                        onClick={() => document.getElementById("fileInput").click()}
                        variant="outlined"
                        color="primary"
                      >
                        Upload
                      </Button>
                      )}
                    </div>
                  </div>
              <div className="w-3/5 mx-auto">
                {uploadedFiles &&
                  uploadedFiles.length > 0 &&
                  uploadedFiles.map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center"
                    >
                      <p>
                        {index + 1}.{" "}
                        {item.length > 50 ? `${item.slice(0, 50)}...` : item}
                      </p>
                      <img
                        src="/close-nobox.png"
                        alt="Remove"
                        className="cursor-pointer w-5"
                        onClick={() => handleRemoveItem(index)}
                      />
                    </div>
                  ))}
              </div>
              
            </Card>
              <div className="mt-4">
                <Button
                  onClick={updateRequirements}
                  variant="contained"
                  color="success"
                >
                  Save Changes
                </Button>
                {error && <p className="text-red-500">{error}</p>}
              </div>
            <Card sx={{ mt: 2 }}>
              <CustomTable columns={columns} data={vendors} onEdit={false} editable={false} />
              </Card> 
              </Box>
              </Box>
        </div>
      </div>
    </div>
  );
};
export default LeadFormPublished;

import axios from "axios";

const registerCustomer = async (customerData) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/auth/register/customer`,
      customerData
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const createLead = async (leadDetails, accessToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/projects`,
      leadDetails,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const uploadToStorage = async (formData, accessToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/file/upload`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const deleteFromStorage = async (fileName, accessToken) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_SERVER}/file/delete/${fileName}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getOpenLeads = async (page, size, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/projects?status=OPEN&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getPublishedLeads = async (page, size, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/projects?status=PUBLISHED&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const updateLead = async (projectId, updatedOptions, accessToken) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER}/projects/${projectId}`,
      updatedOptions,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getLeadById = async (projectId, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/projects/${projectId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getVendors = async (page, size, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/admin/vendors?page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const assignVendors = async (projectId, vendors, accessToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/admin/${projectId}/assign-leads`,
      vendors,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getAssignedVendors = async (projectId, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/admin/get-assigned-vendors/${projectId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getPendingLeads = async (page, size, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/projects/getleads?action=true&status=SENT_PROPOSAL`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const uploadedProposals = async (vendorId, projectNo, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/file/proposals?userId=${vendorId}&projectNo=${projectNo}&type=proposal`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const updateProposalStatus = async (key, projectNo, leadId, accessToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/admin/approve?key=${key}&projectNo=${projectNo}&leadId=${leadId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getProjects = async (page, size, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/projects/getleads?action=true&status=APPROVED&page=${page}&size=${size}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getProjectFileStatus = async (projectNo, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/file/by-project/${projectNo}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const uploadProjectFile = async (formData, accessToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/file/upload`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getProjectFile = async (key, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/file/view/${key}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Error: ${error.message}`);
    throw error;
  }
};

const updateProjectFileStatus = async (id, accessToken) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_SERVER}/file/${id}/status?status=approve`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getMetaValues = async (type, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/metadata/name?type=${type}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error(`Error: ${error.message}`);
    throw error;
  }
};

const sendMessage = async (message, accessToken) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_SERVER}/chat/admin-vendor-chat`,
      message,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const getChats = async (projectNo, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/chat/leads/${projectNo}/chat`,

      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

const uploadedAttachments = async (projectNo, accessToken) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_SERVER}/file/get-site-media?type=site-media&project_no=${projectNo}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.log(`Error: ${error.message}`);
    throw error;
  }
};

export {
  registerCustomer,
  createLead,
  uploadToStorage,
  getOpenLeads,
  getPublishedLeads,
  deleteFromStorage,
  updateLead,
  getLeadById,
  getVendors,
  assignVendors,
  getAssignedVendors,
  getPendingLeads,
  uploadedProposals,
  updateProposalStatus,
  getProjects,
  updateProjectFileStatus,
  getProjectFile,
  getProjectFileStatus,
  uploadProjectFile,
  getMetaValues,
  sendMessage,
  getChats,
  uploadedAttachments,
};

import React, { useEffect, useState } from "react";
import CustomTable from "../common/CustomTable";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import { getPublishedLeads } from "../../api";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";


const PublishedLead = () => {
  const navigate = useNavigate();
  const [leads, setLeads] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 100;
  const [error, setError] = useState(null);

  const fetchLeads = async () => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await getPublishedLeads(
              currentPage - 1,
              itemsPerPage,
              accessToken
            );
      setLeads(response.content);
      setTotalPages(response.totalPages);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again later.");
      }
    }
  };

  useEffect(() => {
    fetchLeads();
  }, [currentPage]);

  const handleEdit = (row) => {
    navigate(`/lead/published/${row.projectId}`); 
  };

  const columns = [
    // { Header: 'Project No', accessor: 'projectNo'},
    { Header: 'Customer Name', accessor: 'customerName' },
    { Header: 'Status', accessor: 'status' },
    { Header: 'Product', accessor: 'technology' },
    { Header: 'Phone', accessor: 'phoneNumber' },
    { Header: 'Location', accessor: 'location' },
    { Header: 'Company', accessor: 'company' },
    { Header: 'Account Owner', accessor: 'accountOwner' },
    { Header: 'Next Update', accessor: 'nextUpdate' },
    { Header: 'Last Contact', accessor: 'lastContact' },
    { Header: 'Priority', accessor: 'priority' },
    { Header: 'Added', accessor: 'timestamp' }, 
    { Header: 'Reference', accessor: 'reference' },
  ];

  return (
    <div className="flex h-full">
      <Header />
      <Sidebar />
      <main className="flex-grow p-11 mt-9 overflow-auto">
        <Card>
        <CustomTable columns={columns} data={leads} onEdit={handleEdit} editable={true} />
        </Card>
      </main>
    </div>
  );
};

export default PublishedLead;

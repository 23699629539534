import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

const DashboardLayout = () => {
  const [showContent, setShowContent] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("Authorization")) {
      setShowContent(true);
    } else {
      setShowContent(false);
    }
  }, []);
  return (
    <div>
      <Outlet />
      {/* {showContent ? (
      ) : (
        <div className="flex">
          {" "}
          <h1>Please Login</h1>
        </div>
      )} */}
    </div>
  );
};

export default DashboardLayout;

import React from "react";
import { Route, BrowserRouter as Routers, Routes } from "react-router-dom";
import Login from "./Components/Login";
import Private from "./Components/Private";
import Customer from "./Components/Project/customer";
import dashboard from "./Components/admin/AllProjects";
import allVendors from "./Components/admin/AllVendors";
import MetaData from "./Components/admin/MetaData";
import PendingLead from "./Components/admin/PendingLead";
import Project from "./Components/admin/Project";
import ProjectTracking from "./Components/admin/ProjectTracking";
import PublishedLead from "./Components/admin/PublishedLead";
import Test from "./Components/admin/Test";
import CreateLead from "./Components/common/CreateLead";
import LeadForm from "./Components/common/LeadForm";
import LeadFormPending from "./Components/common/LeadFormPending";
import LeadFormPublished from "./Components/common/LeadFormPublished";

function App() {
  return (
    <div className="App">
      <Routers>
        <Routes>
          {/* Auth */}
          <Route path="/login" Component={Login} />
          <Route element={<Private />}>
            <Route path="/vendors" Component={allVendors} />
            <Route path="/all-leads" Component={dashboard} />
            <Route path="/test" Component={Test} />
            <Route path="/metadata" Component={MetaData} />
            <Route path="/customers" Component={Customer} />
            <Route path="/lead/:projectId" Component={LeadForm} />
            <Route path="/create-lead" Component={CreateLead} />
            <Route path="/published" Component={PublishedLead} />
            <Route path="/pending" Component={PendingLead} />
            <Route
              path="/lead/published/:projectId"
              Component={LeadFormPublished}
            />
            <Route
              path="/lead/pending/:projectId"
              Component={LeadFormPending}
            />
            <Route path="/projects" Component={ProjectTracking} />
            <Route path="/project/:projectId" Component={Project} />
            <Route path="/test" Component={Test} />
          </Route>
        </Routes>
      </Routers>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { useFormik } from "formik";
import { State, City } from "country-state-city";
import { MultiSelect } from "react-multi-select-component";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Header from "./Header"
import CustomTable from "../common/CustomTable";
import PermMediaIcon from '@mui/icons-material/PermMedia';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {
  Checkbox,
  Pagination,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Box,
  Card,
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  IconButton,
  Modal
} from "@mui/material";
import {
  SiteCondition,
  Solar,
  Wind,
  MeterDetails,
  Inverter,
  Battery,
} from "./Feilds";
import { useNavigate, useParams } from "react-router-dom";
import {
  updateLead,
  deleteFromStorage,
  getLeadById,
  uploadToStorage,
  getVendors,
  assignVendors,
  uploadedAttachments,
  getProjectFile,
  getMetaValues,
} from "../../api";


const stateDistricts = {
  "Andhra Pradesh": [
    "Anantapur",
    "Chittoor",
    "East Godavari",
    "Guntur",
    "Kadapa",
    "Krishna",
    "Kurnool",
    "Prakasam",
    "Nellore",
    "Srikakulam",
    "Visakhapatnam",
    "Vizianagaram",
    "West Godavari",
  ],
  "Arunachal Pradesh": [
    "Anjaw",
    "Changlang",
    "Dibang Valley",
    "East Kameng",
    "East Siang",
    "Kra Daadi",
    "Kurung Kumey",
    "Lohit",
    "Longding",
    "Lower Dibang Valley",
    "Lower Subansiri",
    "Namsai",
    "Papum Pare",
    "Siang",
    "Tawang",
    "Tirap",
    "Upper Siang",
    "Upper Subansiri",
    "West Kameng",
    "West Siang",
    "Itanagar",
  ],
  Assam: [
    "Baksa",
    "Barpeta",
    "Biswanath",
    "Bongaigaon",
    "Cachar",
    "Charaideo",
    "Chirang",
    "Darrang",
    "Dhemaji",
    "Dhubri",
    "Dibrugarh",
    "Goalpara",
    "Golaghat",
    "Hailakandi",
    "Hojai",
    "Jorhat",
    "Kamrup Metropolitan",
    "Kamrup (Rural)",
    "Karbi Anglong",
    "Karimganj",
    "Kokrajhar",
    "Lakhimpur",
    "Majuli",
    "Morigaon",
    "Nagaon",
    "Nalbari",
    "Dima Hasao",
    "Sivasagar",
    "Sonitpur",
    "South Salmara Mankachar",
    "Tinsukia",
    "Udalguri",
    "West Karbi Anglong",
  ],
  Bihar: [
    "Araria",
    "Arwal",
    "Aurangabad",
    "Banka",
    "Begusarai",
    "Bhagalpur",
    "Bhojpur",
    "Buxar",
    "Darbhanga",
    "East Champaran",
    "Gaya",
    "Gopalganj",
    "Jamui",
    "Jehanabad",
    "Kaimur",
    "Katihar",
    "Khagaria",
    "Kishanganj",
    "Lakhisarai",
    "Madhepura",
    "Madhubani",
    "Munger",
    "Muzaffarpur",
    "Nalanda",
    "Nawada",
    "Patna",
    "Purnia",
    "Rohtas",
    "Saharsa",
    "Samastipur",
    "Saran",
    "Sheikhpura",
    "Sheohar",
    "Sitamarhi",
    "Siwan",
    "Supaul",
    "Vaishali",
    "West Champaran",
  ],
  Chhattisgarh: [
    "Balod",
    "Baloda Bazar",
    "Balrampur",
    "Bastar",
    "Bemetara",
    "Bijapur",
    "Bilaspur",
    "Dantewada",
    "Dhamtari",
    "Durg",
    "Gariaband",
    "Janjgir Champa",
    "Jashpur",
    "Kabirdham",
    "Kanker",
    "Kondagaon",
    "Korba",
    "Koriya",
    "Mahasamund",
    "Mungeli",
    "Narayanpur",
    "Raigarh",
    "Raipur",
    "Rajnandgaon",
    "Sukma",
    "Surajpur",
    "Surguja",
  ],
  Goa: ["North Goa", "South Goa"],
  Gujarat: [
    "Ahmedabad",
    "Amreli",
    "Anand",
    "Aravalli",
    "Banaskantha",
    "Bharuch",
    "Bhavnagar",
    "Botad",
    "Chhota Udaipur",
    "Dahod",
    "Dang",
    "Devbhoomi Dwarka",
    "Gandhinagar",
    "Gir Somnath",
    "Jamnagar",
    "Junagadh",
    "Kheda",
    "Kutch",
    "Mahisagar",
    "Mehsana",
    "Morbi",
    "Narmada",
    "Navsari",
    "Panchmahal",
    "Patan",
    "Porbandar",
    "Rajkot",
    "Sabarkantha",
    "Surat",
    "Surendranagar",
    "Tapi",
    "Vadodara",
    "Valsad",
  ],
  Haryana: [
    "Ambala",
    "Bhiwani",
    "Charkhi Dadri",
    "Faridabad",
    "Fatehabad",
    "Gurugram",
    "Hisar",
    "Jhajjar",
    "Jind",
    "Kaithal",
    "Karnal",
    "Kurukshetra",
    "Mahendragarh",
    "Mewat",
    "Palwal",
    "Panchkula",
    "Panipat",
    "Rewari",
    "Rohtak",
    "Sirsa",
    "Sonipat",
    "Yamunanagar",
  ],
  " HimachalPradesh": [
    "Bilaspur",
    "Chamba",
    "Hamirpur",
    "Kangra",
    "Kinnaur",
    "Kullu",
    "Lahaul Spiti",
    "Mandi",
    "Shimla",
    "Sirmaur",
    "Solan",
    "Una",
  ],
  " JammuKashmir": [
    "Anantnag",
    "Bandipora",
    "Baramulla",
    "Budgam",
    "Doda",
    "Ganderbal",
    "Jammu",
    "Kargil",
    "Kathua",
    "Kishtwar",
    "Kulgam",
    "Kupwara",
    "Leh",
    "Poonch",
    "Pulwama",
    "Rajouri",
    "Ramban",
    "Reasi",
    "Samba",
    "Shopian",
    "Srinagar",
    "Udhampur",
  ],
  " Jharkhand": [
    "Bokaro",
    "Chatra",
    "Deoghar",
    "Dhanbad",
    "Dumka",
    "East Singhbhum",
    "Garhwa",
    "Giridih",
    "Godda",
    "Gumla",
    "Hazaribagh",
    "Jamtara",
    "Khunti",
    "Koderma",
    "Latehar",
    "Lohardaga",
    "Pakur",
    "Palamu",
    "Ramgarh",
    "Ranchi",
    "Sahebganj",
    "Seraikela Kharsawan",
    "Simdega",
    "West Singhbhum",
  ],
  " Karnataka": [
    "Bagalkot",
    "Bangalore Rural",
    "Bangalore Urban",
    "Belgaum",
    "Bellary",
    "Bidar",
    "Vijayapura",
    "Chamarajanagar",
    "Chikkaballapur",
    "Chikkamagaluru",
    "Chitradurga",
    "Dakshina Kannada",
    "Davanagere",
    "Dharwad",
    "Gadag",
    "Gulbarga",
    "Hassan",
    "Haveri",
    "Kodagu",
    "Kolar",
    "Koppal",
    "Mandya",
    "Mysore",
    "Raichur",
    "Ramanagara",
    "Shimoga",
    "Tumkur",
    "Udupi",
    "Uttara Kannada",
    "Yadgir",
  ],
  " Kerala": [
    "Alappuzha",
    "Ernakulam",
    "Idukki",
    "Kannur",
    "Kasaragod",
    "Kollam",
    "Kottayam",
    "Kozhikode",
    "Malappuram",
    "Palakkad",
    "Pathanamthitta",
    "Thiruvananthapuram",
    "Thrissur",
    "Wayanad",
  ],
  " MadhyaPradesh": [
    "Agar Malwa",
    "Alirajpur",
    "Anuppur",
    "Ashoknagar",
    "Balaghat",
    "Barwani",
    "Betul",
    "Bhind",
    "Bhopal",
    "Burhanpur",
    "Chhatarpur",
    "Chhindwara",
    "Damoh",
    "Datia",
    "Dewas",
    "Dhar",
    "Dindori",
    "Guna",
    "Gwalior",
    "Harda",
    "Hoshangabad",
    "Indore",
    "Jabalpur",
    "Jhabua",
    "Katni",
    "Khandwa",
    "Khargone",
    "Mandla",
    "Mandsaur",
    "Morena",
    "Narsinghpur",
    "Neemuch",
    "Panna",
    "Raisen",
    "Rajgarh",
    "Ratlam",
    "Rewa",
    "Sagar",
    "Satna",
    "Sehore",
    "Seoni",
    "Shahdol",
    "Shajapur",
    "Sheopur",
    "Shivpuri",
    "Sidhi",
    "Singrauli",
    "Tikamgarh",
    "Ujjain",
    "Umaria",
    "Vidisha",
  ],
  " Maharashtra": [
    "Ahmednagar",
    "Akola",
    "Amravati",
    "Aurangabad",
    "Beed",
    "Bhandara",
    "Buldhana",
    "Chandrapur",
    "Dhule",
    "Gadchiroli",
    "Gondia",
    "Hingoli",
    "Jalgaon",
    "Jalna",
    "Kolhapur",
    "Latur",
    "Mumbai City",
    "Mumbai Suburban",
    "Nagpur",
    "Nanded",
    "Nandurbar",
    "Nashik",
    "Osmanabad",
    "Palghar",
    "Parbhani",
    "Pune",
    "Raigad",
    "Ratnagiri",
    "Sangli",
    "Satara",
    "Sindhudurg",
    "Solapur",
    "Thane",
    "Wardha",
    "Washim",
    "Yavatmal",
  ],
  " Manipur": [
    "Bishnupur",
    "Chandel",
    "Churachandpur",
    "Imphal East",
    "Imphal West",
    "Jiribam",
    "Kakching",
    "Kamjong",
    "Kangpokpi",
    "Noney",
    "Pherzawl",
    "Senapati",
    "Tamenglong",
    "Tengnoupal",
    "Thoubal",
    "Ukhrul",
  ],
  " Meghalaya": [
    "East Garo Hills",
    "East Jaintia Hills",
    "East Khasi Hills",
    "North Garo Hills",
    "Ri Bhoi",
    "South Garo Hills",
    "South West Garo Hills",
    "South West Khasi Hills",
    "West Garo Hills",
    "West Jaintia Hills",
    "West Khasi Hills",
  ],
  " Mizoram": [
    "Aizawl",
    "Champhai",
    "Kolasib",
    "Lawngtlai",
    "Lunglei",
    "Mamit",
    "Saiha",
    "Serchhip",
    "Aizawl",
    "Champhai",
    "Kolasib",
    "Lawngtlai",
    "Lunglei",
    "Mamit",
    "Saiha",
    "Serchhip",
  ],
  " Nagaland": [
    "Dimapur",
    "Kiphire",
    "Kohima",
    "Longleng",
    "Mokokchung",
    "Mon",
    "Peren",
    "Phek",
    "Tuensang",
    "Wokha",
    "Zunheboto",
  ],
  " Odisha": [
    "Angul",
    "Balangir",
    "Balasore",
    "Bargarh",
    "Bhadrak",
    "Boudh",
    "Cuttack",
    "Debagarh",
    "Dhenkanal",
    "Gajapati",
    "Ganjam",
    "Jagatsinghpur",
    "Jajpur",
    "Jharsuguda",
    "Kalahandi",
    "Kandhamal",
    "Kendrapara",
    "Kendujhar",
    "Khordha",
    "Koraput",
    "Malkangiri",
    "Mayurbhanj",
    "Nabarangpur",
    "Nayagarh",
    "Nuapada",
    "Puri",
    "Rayagada",
    "Sambalpur",
    "Subarnapur",
    "Sundergarh",
  ],
  " Punjab": [
    "Amritsar",
    "Barnala",
    "Bathinda",
    "Faridkot",
    "Fatehgarh Sahib",
    "Fazilka",
    "Firozpur",
    "Gurdaspur",
    "Hoshiarpur",
    "Jalandhar",
    "Kapurthala",
    "Ludhiana",
    "Mansa",
    "Moga",
    "Mohali",
    "Muktsar",
    "Pathankot",
    "Patiala",
    "Rupnagar",
    "Sangrur",
    "Shaheed Bhagat Singh Nagar",
    "Tarn Taran",
  ],
  " Rajasthan": [
    "Ajmer",
    "Alwar",
    "Banswara",
    "Baran",
    "Barmer",
    "Bharatpur",
    "Bhilwara",
    "Bikaner",
    "Bundi",
    "Chittorgarh",
    "Churu",
    "Dausa",
    "Dholpur",
    "Dungarpur",
    "Ganganagar",
    "Hanumangarh",
    "Jaipur",
    "Jaisalmer",
    "Jalore",
    "Jhalawar",
    "Jhunjhunu",
    "Jodhpur",
    "Karauli",
    "Kota",
    "Nagaur",
    "Pali",
    "Pratapgarh",
    "Rajsamand",
    "Sawai Madhopur",
    "Sikar",
    "Sirohi",
    "Tonk",
    "Udaipur",
  ],
  " Sikkim": ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"],
  " TamilNadu": [
    "Ariyalur",
    "Chennai",
    "Coimbatore",
    "Cuddalore",
    "Dharmapuri",
    "Dindigul",
    "Erode",
    "Kanchipuram",
    "Kanyakumari",
    "Karur",
    "Krishnagiri",
    "Madurai",
    "Nagapattinam",
    "Namakkal",
    "Nilgiris",
    "Perambalur",
    "Pudukkottai",
    "Ramanathapuram",
    "Salem",
    "Sivaganga",
    "Thanjavur",
    "Theni",
    "Thoothukudi",
    "Tiruchirappalli",
    "Tirunelveli",
    "Tiruppur",
    "Tiruvallur",
    "Tiruvannamalai",
    "Tiruvarur",
    "Vellore",
    "Viluppuram",
    "Virudhunagar",
  ],
  " Telangana": [
    "Adilabad",
    "Bhadradri Kothagudem",
    "Hyderabad",
    "Jagtial",
    "Jangaon",
    "Jayashankar",
    "Jogulamba",
    "Kamareddy",
    "Karimnagar",
    "Khammam",
    "Komaram Bheem",
    "Mahabubabad",
    "Mahbubnagar",
    "Mancherial",
    "Medak",
    "Medchal",
    "Nagarkurnool",
    "Nalgonda",
    "Nirmal",
    "Nizamabad",
    "Peddapalli",
    "Rajanna Sircilla",
    "Ranga Reddy",
    "Sangareddy",
    "Siddipet",
    "Suryapet",
    "Vikarabad",
    "Wanaparthy",
    "Warangal Rural",
    "Warangal Urban",
    "Yadadri Bhuvanagiri",
  ],
  " Tripura": [
    "Dhalai",
    "Gomati",
    "Khowai",
    "North Tripura",
    "Sepahijala",
    "South Tripura",
    "Unakoti",
    "West Tripura",
  ],
  " UttarPradesh": [
    "Agra",
    "Aligarh",
    "Allahabad",
    "Ambedkar Nagar",
    "Amethi",
    "Amroha",
    "Auraiya",
    "Azamgarh",
    "Baghpat",
    "Bahraich",
    "Ballia",
    "Balrampur",
    "Banda",
    "Barabanki",
    "Bareilly",
    "Basti",
    "Bhadohi",
    "Bijnor",
    "Budaun",
    "Bulandshahr",
    "Chandauli",
    "Chitrakoot",
    "Deoria",
    "Etah",
    "Etawah",
    "Faizabad",
    "Farrukhabad",
    "Fatehpur",
    "Firozabad",
    "Gautam Buddha Nagar",
    "Ghaziabad",
    "Ghazipur",
    "Gonda",
    "Gorakhpur",
    "Hamirpur",
    "Hapur",
    "Hardoi",
    "Hathras",
    "Jalaun",
    "Jaunpur",
    "Jhansi",
    "Kannauj",
    "Kanpur Dehat",
    "Kanpur Nagar",
    "Kasganj",
    "Kaushambi",
    "Kheri",
    "Kushinagar",
    "Lalitpur",
    "Lucknow",
    "Maharajganj",
    "Mahoba",
    "Mainpuri",
    "Mathura",
    "Mau",
    "Meerut",
    "Mirzapur",
    "Moradabad",
    "Muzaffarnagar",
    "Pilibhit",
    "Pratapgarh",
    "Raebareli",
    "Rampur",
    "Saharanpur",
    "Sambhal",
    "Sant Kabir Nagar",
    "Shahjahanpur",
    "Shamli",
    "Shravasti",
    "Siddharthnagar",
    "Sitapur",
    "Sonbhadra",
    "Sultanpur",
    "Unnao",
    "Varanasi",
  ],
  " Uttarakhand": [
    "Almora",
    "Bageshwar",
    "Chamoli",
    "Champawat",
    "Dehradun",
    "Haridwar",
    "Nainital",
    "Pauri",
    "Pithoragarh",
    "Rudraprayag",
    "Tehri",
    "Udham Singh Nagar",
    "Uttarkashi",
  ],
  " WestBengal": [
    "Alipurduar",
    "Bankura",
    "Birbhum",
    "Cooch Behar",
    "Dakshin Dinajpur",
    "Darjeeling",
    "Hooghly",
    "Howrah",
    "Jalpaiguri",
    "Jhargram",
    "Kalimpong",
    "Kolkata",
    "Malda",
    "Murshidabad",
    "Nadia",
    "North 24 Parganas",
    "Paschim Bardhaman",
    "Paschim Medinipur",
    "Purba Bardhaman",
    "Purba Medinipur",
    "Purulia",
    "South 24 Parganas",
    "Uttar Dinajpur",
  ],
  " AndamanNicobar": ["Nicobar", "North Middle Andaman", "South Andaman"],
  " Chandigarh": ["Chandigarh"],
  " DadraHaveli": ["Dadra Nagar Haveli"],
  " DamanDiu": ["Daman", "Diu"],
  " Delhi": [
    "Central Delhi",
    "East Delhi",
    "New Delhi",
    "North Delhi",
    "North East Delhi",
    "North West Delhi",
    "Shahdara",
    "South Delhi",
    "South East Delhi",
    "South West Delhi",
    "West Delhi",
  ],
  " Lakshadweep": ["Lakshadweep"],
  " Puducherry": ["Karaikal", "Mahe", "Puducherry", "Yanam"],
};

const LeadForm = () => {
  const { projectId } = useParams();
  const [leadId, setLeadId] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [assignedVendors, setAssignedVendors] = useState([]);
  const [selected3, setSelected3] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [techOptions, setTechOptions] = useState([]);
  const [referenceOptions, setReferenceOptions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(5);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const fetchOptions = async (type, setter) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const res = await getMetaValues(type, accessToken);
      setter(res);
    } catch (error) {
      console.log("Failed to load metadata", error);
    }
  };

  useEffect(() => {
    fetchOptions("technology", setTechOptions);
    fetchOptions("type_of_installation", setReferenceOptions);
  }, []);

  const addressFormik = useFormik({
    initialValues: {
      country: null,
      state: null,
      city: null,
    },
    onSubmit: (values) => console.log(JSON.stringify(values)),
  });
  const { values, setValues } = addressFormik;
  const [requirementForm, setRequirementForm] = useState({
    typeOfInstallation: "",
    priority: "",
    location: "",
    pincode: "",
    //site condition
    avgBill: 0,
    siteCoordinates: "",
    numberOfFloors: 0,
    totalFlats: 0,
    roofTopArea: "",
    siteDescription: "",
    // solar
    solarCapacity: 0,
    solarBrand: "",
    solarType: "",
    // wind
    windCapacity: 0,
    windBrand: "",
    windType: "",
    // meter
    meterType: "",
    numberOfMeter: 0,
    consumerNumber: "",
    consumerType: "",
    sanctionedLoad: "",
    contractDemand: "",
    // inverter
    inverterCapacity: 0,
    connectionType: "",
    batteryBackupCapacity: "",
    batteryCapacity: "",
    batteryType: "",
    numberOfBatteries: "",
    systemVoltage: "",
    backupPower: "",
    // power
    projectStatus: "",
    product: "",
    accountOwner: "",
  });


  const handleRequirementChange = (event) => {
    const { name, value } = event.target;
    setRequirementForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleAssignedVendors = async () => {
    const selectedVendors = assignedVendors.map((vendor) => vendor.id);
    const parsedVendors = selectedVendors.map((str) => parseInt(str, 10));
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await assignVendors(
        requirementForm.projectId,
        parsedVendors,
        accessToken
      );
      console.log(response);
    } catch (error) {
      console.error("Error fetching vendors: ", error);
      setError(
        "An error occurred while fetching vendors. Please try again later."
      );
    }
    toast.success("Vendor has been assigned", {
      position: "bottom-right",
      autoClose: 5000, // Toast will close after 5 seconds
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };

  const updateRequirements = async () => {
    const accessToken = localStorage.getItem("Authorization");

    try {
      const response = await updateLead(
        requirementForm.projectId,
        requirementForm,
        accessToken
      );

      console.log(response);
    } catch (error) {
      console.error("Failed to update lead", error);
      setError("An error occurred. Please try again later.");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleUpload(file, requirementForm.projectNo);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const file = droppedFiles[0];
      handleUpload(file);
    }
  };

  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
  };

  const fetchAttachments = async (projectNo) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await uploadedAttachments(projectNo, accessToken);
      const files = response.data.files;
      setAttachments(files);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again later.");
      }
    }
  };

  const isVideoFile = (key) => {
    const videoExtensions = ['mp4', 'mov', 'avi', 'wmv', 'flv'];
    const extension = key.split('.').pop();
    return videoExtensions.includes(extension);
  };

  const isPDFFile = (key) => {
    const pdfExtensions = ['pdf'];
    const extension = key.split('.').pop();
    return pdfExtensions.includes(extension);
  };

  const handleUpload = async (file, projectNo) => {
    const accessToken = localStorage.getItem("Authorization");
    if (!projectNo) {
      setError("Please create a project");
      return;
    }

    setUploading(true);

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "site-media");
      formData.append("project_no", projectNo);

      const response = await uploadToStorage(formData, accessToken);
      uploadedFiles.push(response.data.filename);
      setUploadedFiles(uploadedFiles);
      // if (localStorage.getItem("uploadedFiles")) {
      //   const uploadedFiles = JSON.parse(localStorage.getItem("uploadedFiles"));
      //   uploadedFiles.push(response.data.filename);
      //   localStorage.setItem("uploadedFiles", JSON.stringify(uploadedFiles));
      //   setUploadedFiles(uploadedFiles);
      // } else {
      //   const uploadedFiles = [];
      //   uploadedFiles.push(response.data.filename);
      //   localStorage.setItem("uploadedFiles", JSON.stringify(uploadedFiles));
      //   setUploadedFiles(uploadedFiles);
      // }
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Error uploading file");
    }

    setUploading(false);
  };

  const isRowSelected = (id) => {
    return assignedVendors.some((vendor) => vendor.id === id);
  };

  const handleRowCheck = (event, id) => {
    if (event.target.checked) {
      const selectedVendor = vendors.find((vendor) => vendor.id === id);
      setAssignedVendors([...assignedVendors, selectedVendor]);
    } else {
      setAssignedVendors(assignedVendors.filter((vendor) => vendor.id !== id));
    }
  };

  const handleRemoveItem = async (indexToRemove) => {
    const accessToken = localStorage.getItem("Authorization");
    const response = await deleteFromStorage(
      uploadedFiles[indexToRemove],
      accessToken
    );

    const newUploadedFiles = uploadedFiles.filter(
      (_, index) => index !== indexToRemove
    );

    setUploadedFiles(newUploadedFiles);
  };

  const handleSubmit = async () => {
    await updateRequirements();
    localStorage.removeItem("uploadedFiles");
    setUploadedFiles(null);
    try {
      // Simulated saving logic (this could be an API call, for example)
      // If successful:
      toast.success("Changes saved", {
        position: "bottom-right",
        autoClose: 5000, // Toast will close after 5 seconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (e) {
      setError("Failed to save changes");
    }
  };

  useEffect(() => {
    if (values.state) {
      const updatedCities = City.getCitiesOfState(
        values.country.isoCode,
        values.state.isoCode
      ).map((city) => ({
        label: city.name,
        value: city.isoCode,
        ...city,
      }));
      setCities(updatedCities);
    }
  }, [values.state]);

  const getTechnologyText = (value) => {
    const options = {
      "Solar on-grid": "solar-on-grid",
      "Solar off-grid": "solar-off-grid",
      "Wind on-grid": "wind-on-grid",
      "Wind off-grid": "wind-off-grid",
      "S&W off-grid": "sw-off-grid",
      "S&W on-grid": "sw-on-grid",
      "Hybrid(ON-OFF grid) solar": "hybrid-solar-on-off-grid",
      "Hybrid(ON-OFF grid) wind": "hybrid_wind_on_off_grid",
      "Hybrid(ON-OFF grid) Wind+Solar": "hybrid_wind_solar_on_off_grid",
    };

    return options[value] || "";
  };

  const handleImageClick = async (imageUrl) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const response = await getProjectFile(imageUrl, accessToken);
      window.open(response, "_blank");
    } catch (error) {
      console.error("Error uploading file:", error);
      setError("Error uploading file");
    }
  };

  useEffect(() => {
    const getLead = async () => {
      const accessToken = localStorage.getItem("Authorization");
      try {
        const response = await getLeadById(projectId, accessToken);
        console.log(response);
        setLeadId(response.projectId);
        setRequirementForm(response);
        fetchAttachments(response.projectNo);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message);
        } else {
          setError("An error occurred. Please try again later.");
        }
      }
    };
    getLead();
  }, []);

  const columns = [
    { Header: 'Name', accessor: 'name', width: '180px' },
    { Header: 'Company', accessor: 'company' },
    { Header: 'State', accessor: 'state' },
    { Header: 'City', accessor: 'city' },
    { Header: 'Address', accessor: 'address' },
    { Header: 'Pincode', accessor: 'pincode' },
  ];

  const priorityOptions = ["High", "Medium", "Low"];

  const typeOfInstallationOptions = [ "Industrial","Commercial", "Residential Society", "Residential Home"];

  useEffect(() => {
    const getUserByVendor = async () => {
      const accessToken = localStorage.getItem("Authorization");
      try {
        const response = await getVendors(
          currentPage - 1,
          itemsPerPage,
          accessToken
        );
        setTotalPages(response.totalPages);

        const vendorDataArray = response.content?.map((vendor) => ({
          id: vendor.userId?.toString(),
          name: vendor.firstname + " " + vendor.lastname,
          state: null,
          city: vendor?.city,
          address: vendor?.address,
          company: vendor?.companyName,
          pincode: vendor?.pincode,
        }));
        setVendors(vendorDataArray);
        console.log(response.content);
      } catch (error) {
        console.error("Error fetching vendors: ", error);
        setError(
          "An error occurred while fetching vendors. Please try again later."
        );
      }
    };
    getUserByVendor();
  }, [currentPage]);

  const renderComponents = () => {
    switch (requirementForm.technology) {
      case "solar-on-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "solar-off-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "wind-on-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "wind-off-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "sw-off-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "sw-on-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "hybrid-solar-on-off-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "hybrid_wind_on_off_grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "hybrid_wind_solar_on_off_grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              handleReq
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
              uirementChange
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      default:
        return null;
    }
  };

  const productOptions = [
    { label: "Solar On-grid", value: "solar_on_grid" },
    { label: "Wind Turbine", value: "wind_turbine" },
    { label: "Solar Off-grid", value: "solar_off_grid" },
    { label: "Solar System", value: "solar_system" },
    { label: "Tulip Wind Turbine", value: "tulip_wind_turbine" },
    { label: "Agri Solar System", value: "agri_solar_system" },
    { label: "(Solar+Wind) Hybrid System", value: "solar_wind_hybrid_system" },
    { label: "Solar Hybrid (On-grid+Off-grid)", value: "solar_hybrid_system" },
    {
      label: "Solar water pumping system",
      value: "solar_water_pumping_system",
    },
    {
      label: "Vertical Axis Wind Turbine",
      value: "vertical_axis_wind_turbine",
    },
    { label: "Street Lighting", value: "street_lighting" },
    { label: "Opex", value: "opex" },
    {
      label: "Wind Turbine Off-grid System",
      value: "wind_turbine_off_grid_system",
    },
    { label: "Flower Turbine", value: "flower_turbine" },
    { label: "O&M", value: "o_and_m" },
    { label: "Exhaust Wind Turbine", value: "exhaust_wind_turbine" },
  ];

  const [selectedState, setSelectedState] = useState("");
  const [districts, setDistricts] = useState([]);

  const handleStateChange = (event) => {
    const state = event.target.value;
    setSelectedState(state);
    setDistricts(stateDistricts[state] || []);
  };
  
  return (
    <div className="flex">   
      <div>
      <Header />
      <Sidebar />
      </div>
        <div className="mt-20">
        <Box sx={{ ml: 3, mr: 3 }}>
        <Box sx={{ ml: 3, mr: 3, p: 2 }}>

        <Card sx={{ p: 3 }}>
        <Typography variant="h5" component="div" className="m-2">
          Requirement
        </Typography>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="typeOfInstallation">Type of Installation</InputLabel>
              <Select
                id="typeOfInstallation"
                name="typeOfInstallation"
                value={requirementForm.typeOfInstallation ?? ""}
                onChange={handleRequirementChange}
                // placeholder="Residential / Society etc."
                label="Type of Installation"
              >
               <MenuItem value="">None</MenuItem>
              {typeOfInstallationOptions.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
                </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="location"
              name="location"
              label="Location"
              value={requirementForm?.location ?? ""}
              onChange={handleRequirementChange}
              placeholder="Enter location"
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              id="pincode"
              name="pincode"
              label="Pincode"
              value={requirementForm?.pincode ?? ""}
              onChange={handleRequirementChange}
              placeholder="Enter pincode"
              fullWidth
              margin="normal"
            />
          </Grid>
          <Grid item xs={12} sm={3}>
          <FormControl fullWidth margin="normal">
            <InputLabel htmlFor="priority">Priority</InputLabel>
            <Select
              id="priority"
              name="priority"
              value={requirementForm.priority ?? ""}
              onChange={handleRequirementChange}
              label="Priority"
            >
              <MenuItem value="">None</MenuItem>
              {priorityOptions.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="subtitle1" gutterBottom>
              Attachments
            </Typography>
            {/* <Box display="flex">
              {attachments.map((imageUrl, index) => (
                // <img
                //   key={index}
                //   src="/image.png"
                //   alt={`Attachment ${index}`}
                //   className="cursor-pointer"
                //   style={{ marginRight: '8px', marginBottom: '8px', width: '32px', height: '32px' }}
                //   onClick={() => handleImageClick(imageUrl)}
                // />
                <IconButton
                key={index}
                onClick={() => handleImageClick(imageUrl)}
                className="cursor-pointer"
                sx={{ marginRight: '8px', marginBottom: '8px', width: '64px', height: '64px' }}
              >
                <ImageIcon sx={{ width: '100%', height: '100%' }} />
              </IconButton>
              ))}
            </Box> */}
            <Box>
              <IconButton
                onClick={handleOpen}
                className="cursor-pointer"
                sx={{ marginRight: '8px', marginBottom: '8px', width: '64px', height: '64px' }}
              >
              <PermMediaIcon sx={{ width: '100%', height: '100%' }}/>
              </IconButton>

              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="attachments-modal-title"
                aria-describedby="attachments-modal-description"
              >
             <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 3,
                borderRadius: 1,
              }}
            >
              <Typography variant="subtitle1" gutterBottom sx={{ textAlign: 'left' }}>
              Find Attachments
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', mt: 2 }}>
                <Grid container spacing={2} justifyContent="center">
                  {attachments.map(({ key }, index) => {
                    const isVideo = isVideoFile(key);
                    const isPDF = isPDFFile(key);
                    return (
                      <Grid item key={index}>
                        <IconButton
                          onClick={() => handleImageClick(key)}
                          sx={{
                            padding: '10px',
                            width: '84px',
                            height: '84px',
                            position: 'relative',
                            borderRadius: 0
                          }}
                        >
                          <img
                            src={`https://energybae-bucket.s3.ap-south-1.amazonaws.com/${key}`}
                            alt={isVideo || isPDF ? "" : `Thumbnail ${index + 1}`}
                            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '10px' }}
                          />
                          {isVideo && (
                            <PlayCircleIcon
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                width: '74px',
                                height: '74px',
                                transform: 'translate(-50%, -50%)',
                                color: '#EEF5FF',
                                fontSize: 70,
                                backgroundColor: '#BED7DC',
                                borderRadius: '10px'
                              }}
                            />
                          )}
                          {isPDF && (
                            <PictureAsPdfIcon
                              sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                width: '74px',
                                height: '74px',
                                transform: 'translate(-50%, -50%)',
                                color: '#EEF5FF',
                                fontSize: 70,
                                backgroundColor: '#BED7DC',
                                borderRadius: '10px'
                              }}
                            />
                          )}
                        </IconButton>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            </Box>
            </Modal>
            </Box>
            </Grid>
          </Grid>
        </Card>
        <Card sx={{ p: 3, mt: 2 }}>
          <Typography variant="h5" component="div" className="m-2">
                Technology
              </Typography>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
              <FormControl fullWidth margin="normal">
                <InputLabel htmlFor="technology">Technology</InputLabel>
                <Select
                  id="technology"
                  name="technology"
                  value={requirementForm.technology ?? ""}
                  onChange={handleRequirementChange}
                  label="Technology"
                  >
                  <MenuItem value={null}>Select Technology</MenuItem>
                  {techOptions.map((item, index) => (
                      <option key={index} value={getTechnologyText(item)}>
                        {item}
                      </option>
                    ))}
                </Select>
              </FormControl>
              </Grid>
              </Grid>
              {renderComponents()}
          </Card>
          {/* </Box> */}
          {/* <Box sx={{ ml: 3, mr: 3, p: 2 }}> */}
          <Card sx={{ p: 3, mt: 2 }}>
              <Typography variant="h5" component="div" className="m-4">
                Attach Site Images and Videos
              </Typography>

                  <div className="flex flex-col items-center mt-6">
                    <div
                      className="border-2 border-dashed rounded-lg bg-gray-100 border-gray-400 p-10 text-center flex flex-col items-center w-4/5"
                      onDragOver={handleDragOver}
                      onDrop={handleDrop}
                    >
                      <p className="text-lg">
                        {uploading ? "Uploading..." : "Drag and drop file here"}
                      </p>

                      <input
                        id="fileInput"
                        type="file"
                        onChange={handleFileChange}
                        multiple
                        className="hidden"
                      />
                      {!uploading && (
                        // <button
                        //   onClick={() =>
                        //     document.getElementById("fileInput").click()
                        //   }
                        //   className="bg-slate-400 text-white rounded-lg p-1"
                        // >
                        //   Upload
                        // </button>
                        <Button
                        onClick={() => document.getElementById("fileInput").click()}
                        variant="outlined"
                        color="primary"
                      >
                        Upload
                      </Button>
                      )}
                    </div>
                  </div>
              <div className="w-3/5 mx-auto">
                {uploadedFiles &&
                  uploadedFiles.length > 0 &&
                  uploadedFiles.map((item, index) => (
                    <div
                      key={index}
                      className="flex justify-between items-center"
                    >
                      <p>
                        {index + 1}.{" "}
                        {item.length > 50 ? `${item.slice(0, 50)}...` : item}
                      </p>
                      <img
                        src="/close-nobox.png"
                        alt="Remove"
                        className="cursor-pointer w-5"
                        onClick={() => handleRemoveItem(index)}
                      />
                    </div>
                  ))}
              </div>
              
            </Card>
            <div className="mt-4">
                <Button
                  onClick={handleSubmit}
                  variant="contained"
                  color="success"
                >
                  Save Changes
                </Button>
                {error && <p className="text-red-500">{error}</p>}
              </div>

              <Card sx={{ p: 3, mt: 2 }}>
              <Typography variant="h5" component="div" className="m-4">
                Assign Vendors
              </Typography>              
              <Grid container spacing={2} marginTop={1}>
              <form className="  ml-10 mx-auto mt-4 flex gap-4">
                  {/* <div className="p-4 flex"> */}
                    {/* <div className="mb-4">
                      <label
                        htmlFor="inputState"
                        className="block mb-2 text-sm font-medium text-black "
                      >
                        State
                      </label>
                      <select
                        id="inputState"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md mx-2 w-3/4 p-2.5 "
                        value={selectedState}
                        onChange={handleStateChange}
                      >
                        <option value="">Select State</option>
                        {Object.keys(stateDistricts).map((state) => (
                          <option key={state} value={state}>
                            {state}
                          </option>
                        ))}
                      </select>
                    </div> */}
                     <Grid item xs={12} sm={4}>
                   <InputLabel id="inputStateLabel">State</InputLabel>
                    <FormControl fullWidth margin="dense">
                        <Select
                          labelId="inputStateLabel"
                          id="inputState"
                          value={selectedState}
                          onChange={handleStateChange}
                          size="small"
                          sx={{ width: '120px'}}
                        >
                          <MenuItem value="">Select State</MenuItem>
                          {Object.keys(stateDistricts).map((state) => (
                            <MenuItem key={state} value={state}>
                              {state}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                  <InputLabel id="inputDistrict">District</InputLabel>
                  <FormControl fullWidth margin="dense">
                    <Select
                      labelId="inputDistrict"
                      id="inputDistrict"
                      size="small"
                      sx={{ width: '120px'}}
                    >
                      <MenuItem value="">Select District</MenuItem>
                      {districts.map((district) => (
                        <MenuItem key={district} value={district}>
                          {district}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </Grid>
                  {/* </div> */}

                  <Grid item xs={12} sm={4}>
                  <InputLabel id="inputDistrictLabel">Filter by Products</InputLabel>
                  <FormControl fullWidth margin="dense">
                    <MultiSelect
                      options={productOptions}
                      value={selected3}
                      onChange={setSelected3}
                    />
                  </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ mt:4, width: '160px', height: '38px' }}
                    size="small"
                  >
                  Search Vendors
                  </Button>
                  </Grid>
                </form>
              </Grid>

                <div className="mt-9">
                  <TableContainer className="mx-auto" component={Paper}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell padding="checkbox"></TableCell>{" "}
                          <TableCell>Name</TableCell>
                          <TableCell>Company</TableCell>
                          <TableCell>State</TableCell>
                          <TableCell>City</TableCell>
                          <TableCell>Address</TableCell>
                          <TableCell>Pincode</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {vendors?.map((row, index) => (
                          <TableRow
                            key={row.id}
                            selected={isRowSelected(row.id)}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isRowSelected(row.id)}
                                onChange={(event) =>
                                  handleRowCheck(event, row.id)
                                }
                              />
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell component="th" scope="row">
                              {row.company}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.state}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {row.city}
                            </TableCell>
                            <TableCell>{row.address}</TableCell>
                            <TableCell>{row.pincode}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div className="mt-4 flex justify-center">
                    <Pagination
                      count={totalPages}
                      page={currentPage}
                      onChange={handlePaginationChange}
                      shape="rounded"
                    />
                  </div>
                </div>
                 
              <div className="flex justify-center">
                <button
                  onClick={handleAssignedVendors}
                  type="submit"
                  className="mt-8 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded h-10"
                >
                  Assign Vendors
                </button>
              </div>
              </Card>

        </Box>
        </Box>
          </div>
        </div>

  );
};

export default LeadForm;

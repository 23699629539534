import React, { useEffect, useState } from "react";
import { getMetaValues } from "../../api";
import TextField from '@mui/material/TextField';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { Card, Grid, FormControl, InputLabel, Typography } from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

const SiteCondition = ({ requirementForm, handleRequirementChange }) => {
  return (
    <Card sx={{ p: 3, mt: 1 }}>
    <Typography variant="h6" component="div" className="m-2">
      Site Condition
      </Typography>
    <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Average electricity bill"
            name="avgBill"
            value={requirementForm.avgBill ?? ""}
            onChange={handleRequirementChange}
            variant="outlined"
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Site Coordinates"
            name="siteCoordinates"
            value={requirementForm.siteCoordinates ?? ""}
            onChange={handleRequirementChange}
            variant="outlined"
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="No. of floors"
            name="numberOfFloors"
            value={requirementForm.numberOfFloors ?? ""}
            onChange={handleRequirementChange}
            type="number"
            variant="outlined"
            InputProps={{ inputProps: { min: 0, max: 10, step: 1 } }}
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Total flats"
            name="totalFlats"
            value={requirementForm.totalFlats ?? ""}
            onChange={handleRequirementChange}
            type="number"
            variant="outlined"
            InputProps={{ inputProps: { min: 0, max: 20, step: 1 } }}
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Rooftop area"
            name="roofTopArea"
            value={requirementForm.roofTopArea ?? ""}
            onChange={handleRequirementChange}
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextareaAutosize
            fullWidth
            aria-label="Site description"
            rowsMin={4}
            placeholder="Site description"
            name="siteDescription"
            value={requirementForm.siteDescription ?? ""}
            onChange={handleRequirementChange}
            className="border rounded-md p-2 h-auto"
          />
        </Grid>
      </Grid>
      </Card>
  );
};

const Solar = ({ requirementForm, handleRequirementChange }) => {
  const [brandOptions, setBrandOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);

  const fetchOptions = async (type, setter) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const res = await getMetaValues(type, accessToken);
      setter(res);
    } catch (error) {
      console.log("Failed to load metadata", error);
    }
  };

  useEffect(() => {
    fetchOptions("solar_brand", setBrandOptions);
    fetchOptions("solar_module_type", setTypeOptions);
  }, []);

  return (
    <Card sx={{ p: 3, mt: 1 }}>
      {/* <Typography variant="h6" component="h2" align="center" sx={{ fontWeight: 'bold' }}> */}
      <Typography variant="h6" component="div" className="m-2">
        Solar
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Solar Capacity"
            name="solarCapacity"
            value={requirementForm.solarCapacity ?? ""}
            onChange={handleRequirementChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Solar Brand</InputLabel>
            <Select
              name="solarBrand"
              value={requirementForm.solarBrand || "NA"}
              onChange={(event) => {
                handleRequirementChange({
                  target: {
                    name: event.target.name,
                    value: event.target.value,
                  },
                });
              }}
              label="Solar Brand"
            >
              <MenuItem value="NA">None</MenuItem>
              {brandOptions.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Solar Module Type</InputLabel>
            <Select
              name="solarType"
              value={requirementForm.solarType || "NA"}
              onChange={(event) => {
                handleRequirementChange({
                  target: {
                    name: event.target.name,
                    value: event.target.value,
                  },
                });
              }}
              label="Solar Module Type"
            >
              <MenuItem value="NA">None</MenuItem>
              {typeOptions.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Solar;

const Wind = ({ requirementForm, handleRequirementChange }) => {
  const [typeOptions, setTypeOptions] = useState([]);
  const [brandOptions, setBrandOptions] = useState([]);

  const fetchOptions = async (type, setter) => {
    if (type === "") {
      setter([]);
      return;
    }
    const accessToken = localStorage.getItem("Authorization");
    try {
      const res = await getMetaValues(type, accessToken);
      setter(res);
    } catch (error) {
      console.log("Failed to load metadata", error);
    }
  };

  useEffect(() => {
    fetchOptions("wind_type", setTypeOptions);
    fetchOptions("wind_brand", setBrandOptions);
  }, []);

  return (
    <Card sx={{ p: 3, mt: 1}}>
      <Typography variant="h6" component="div" className="m-2">
      Wind
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={12} sm={3}>
        <TextField
          label="Wind Capacity"
          name="windCapacity"
          value={requirementForm.windCapacity ?? ""}
          onChange={handleRequirementChange}
          variant="outlined"
          fullWidth
          margin="normal"
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel>Type</InputLabel>
          <Select
            name="windType"
            value={requirementForm.windType ?? ""}
            onChange={(event) => {
              handleRequirementChange({
                target: {
                  name: event.target.name,
                  value: event.target.value,
                },
              });
            }}
            label="Type"
          >
            <MenuItem value="NA">None</MenuItem>
            {typeOptions.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormControl fullWidth margin="normal" variant="outlined">
          <InputLabel>Wind Turbine</InputLabel>
          <Select
            name="windBrand"
            value={requirementForm.windBrand ?? ""}
            onChange={(event) => {
              handleRequirementChange({
                target: {
                  name: event.target.name,
                  value: event.target.value,
                },
              });
            }}
            label="Wind Turbine"
          >
            <MenuItem value="NA">None</MenuItem>
            {brandOptions.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  </Card>
  );
};

const MeterDetails = ({ requirementForm, handleRequirementChange }) => {
  const [meterOptions, setMeterOptions] = useState([]);
  const [connectionOptions, setConnectionOptions] = useState([]);

  // Function to fetch metadata
  const fetchOptions = async (type, setter) => {
    if (type === "") {
      setter([]);
      return;
    }
    const accessToken = localStorage.getItem("Authorization");
    try {
      const res = await getMetaValues(type, accessToken);
      setter(res);
    } catch (error) {
      console.log("Failed to load metadata", error);
    }
  };

  // Using useEffect to trigger fetching only once
  useEffect(() => {
    fetchOptions("meter_type", setMeterOptions);
    fetchOptions("meter_connection_type", setConnectionOptions);
  }, []);

  return (
    <Card sx={{ p: 3, mt: 1 }}>
    <Typography variant="h6" component="div" className="m-2">
      Meter Type
      </Typography>    
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="meterType">Meter Type</InputLabel>
                <Select
                  id="meterType"
                  name="meterType"
                  value={requirementForm.meterType ?? ""}
                  onChange={handleRequirementChange}
                  label="Meter Type"
                  >
                <MenuItem value="NA">None</MenuItem>
            {meterOptions.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Number of Meters"
            name="numberOfMeter"
            value={requirementForm.numberOfMeter ?? ""}
            onChange={handleRequirementChange}
            variant="outlined"
            className="border rounded-md p-2"
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Consumer Number"
            name="consumerNumber"
            value={requirementForm.consumerNumber ?? ""}
            onChange={handleRequirementChange}
            variant="outlined"
            className="border rounded-md p-2"
            margin="normal"
          />
        </Grid>

        <Grid item xs={12} sm={3}>
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="consumerType">Customer Type</InputLabel>
                <Select
                  id="consumerType"
                  name="consumerType"
                  value={requirementForm.consumerType ?? ""}
                  onChange={handleRequirementChange}
                  label="Customer Type"
                  >
                  <MenuItem value="NA">None</MenuItem>
              {connectionOptions.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Sanctioned Load"
            name="sanctionedLoad"
            value={requirementForm.sanctionedLoad ?? ""}
            onChange={handleRequirementChange}
            variant="outlined"
            className="border rounded-md p-2"
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            fullWidth
            label="Contract Demand"
            name="contractDemand"
            value={requirementForm.contractDemand ?? ""}
            onChange={handleRequirementChange}
            variant="outlined"
            className="border rounded-md p-2"
          />
        </Grid>
      </Grid>
  </Card>
  );
};

const Inverter = ({ requirementForm, handleRequirementChange }) => {
  const [connectionTypeOptions, setConnectionTypeOptions] = useState([]);

  // Function to fetch metadata
  const fetchOptions = async (type, setter) => {
    if (type === "") {
      setter([]);
      return;
    }
    const accessToken = localStorage.getItem("Authorization");
    try {
      const res = await getMetaValues(type, accessToken);
      setter(res);
    } catch (error) {
      console.log("Failed to load metadata", error);
    }
  };

  // Using useEffect to trigger fetching only once or based on specific conditions
  useEffect(() => {
    fetchOptions("inverter_connection_type", setConnectionTypeOptions);
  }, []);

  return (
    <Card sx={{ p: 3, mt: 1 }}>
    <Typography variant="h6" component="div" className="m-2">
        Inverter
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Capacity"
            name="inverterCapacity"
            value={requirementForm.inverterCapacity ?? ""}
            onChange={handleRequirementChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Connection Type</InputLabel>
            <Select
              name="connectionType"
              value={requirementForm.connectionType ?? ""}
              onChange={(event) => {
                handleRequirementChange({
                  target: {
                    name: event.target.name,
                    value: event.target.value,
                  },
                });
              }}
              label="Connection Type"
            >
              {connectionTypeOptions.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Card>
  );
};

const Battery = ({ requirementForm, handleRequirementChange }) => {
  const [batteryTypeOptions, setBatteryTypeOptions] = useState([]);

  // Function to fetch metadata
  const fetchOptions = async (type, setter) => {
    if (type === "") {
      setter([]);
      return;
    }
    const accessToken = localStorage.getItem("Authorization");
    try {
      const res = await getMetaValues(type, accessToken);
      setter(res);
    } catch (error) {
      console.log("Failed to load metadata", error);
    }
  };

  // Use useEffect to handle the fetching of options only once
  useEffect(() => {
    fetchOptions("battery_type", setBatteryTypeOptions);
  }, []);

  return (
    <Card sx={{ p: 3, mt: 1 }}>
    <Typography variant="h6" component="div" className="m-2">
        Battery
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Battery Backup Capacity"
            name="batteryBackupCapacity"
            value={requirementForm.batteryBackupCapacity ?? ""}
            onChange={handleRequirementChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Battery Capacity"
            name="batteryCapacity"
            value={requirementForm.batteryCapacity ?? ""}
            onChange={handleRequirementChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth margin="normal" variant="outlined">
            <InputLabel>Battery Type</InputLabel>
            <Select
              name="batteryType"
              value={requirementForm.batteryType ?? ""}
              onChange={(event) => {
                handleRequirementChange({
                  target: {
                    name: event.target.name,
                    value: event.target.value,
                  },
                });
              }}
              label="Battery Type"
            >
              {/* <MenuItem value="NA">None</MenuItem> */}
              {batteryTypeOptions.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Number of Batteries"
            name="numberOfBatteries"
            value={requirementForm.numberOfBatteries ?? ""}
            onChange={handleRequirementChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="System Voltage"
            name="systemVoltage"
            value={requirementForm.systemVoltage ?? ""}
            onChange={handleRequirementChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            label="Back Up Power"
            name="backupPower"
            value={requirementForm.backupPower ?? ""}
            onChange={handleRequirementChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>
    </Card>
  );
};

export { SiteCondition, Solar, Wind, MeterDetails, Inverter, Battery };

import React, { useEffect, useState } from "react";
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import { getPendingLeads } from "../../api";
import CustomTable from "../common/CustomTable";
import { useNavigate } from "react-router-dom";
import { Card } from "@mui/material";


const PendingLead = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [leads, setLeads] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 100;

  

  useEffect(() => {
    const getLeads = async () => {
      const accessToken = localStorage.getItem("Authorization");
      try {
        const response = await getPendingLeads(
          currentPage - 1,
          itemsPerPage,
          accessToken
        );
        setTotalPages(response.totalPages);
        setLeads(response.content);
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          setError(error.response.data.message);
        } else {
          setError("An error occurred. Please try again later.");
        }
      }
    };
    getLeads();
  }, [currentPage]);




  const handleEdit = (row) => {
    navigate(`/lead/pending/${row.projectId}?projectNo=${row.projectNo}&vendorId=${row.vendorId}&leadId=${row.leadId}`);
  };

  const columns = [
    // { Header: 'Project No', accessor: 'projectNo' },
    { Header: 'Customer Name', accessor: 'customerName' },
    { Header: 'Vendor Name', accessor: 'vendorName' },
    { Header: 'Product', accessor: 'technology' },
    { Header: 'Status', accessor: 'leadStatus' },
  ];

  return (
    <div className="flex h-full">
      <Header />
      <Sidebar />
      <main className="flex-grow p-11 mt-9 overflow-auto">
        <Card>
        <CustomTable columns={columns} data={leads} onEdit={handleEdit} editable={true} />
        </Card>
      </main>
    </div>
  );

};

export default PendingLead;

import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import {
  SiteCondition,
  Solar,
  Wind,
  MeterDetails,
  Inverter,
  Battery,
} from "./Feilds";
import { useNavigate } from "react-router-dom";
import {
  createLead,
  deleteFromStorage,
  getMetaValues,
  registerCustomer,
  uploadToStorage,
} from "../../api";
import { toast } from "react-toastify";
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  Card,
  Typography,
  Grid,
} from '@mui/material';
import Header from "./Header";


const CreateLead = () => {
  const navigate = useNavigate();
  const [selectedTechnology, setSelectedTechnology] = useState("");
  const [priority, setPriority] = useState("");
  const [reference, setReference] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [isLeadCreated, setIsLeadCreated] = useState(false);
  const [referenceOptions, setReferenceOptions] = useState([]);
  // const [techOptions, setTechOptions] = useState([]);


  const fetchOptions = async (type, setter) => {
    const accessToken = localStorage.getItem("Authorization");
    try {
      const res = await getMetaValues(type, accessToken);
      setter(res);
    } catch (error) {
      console.log("Failed to load metadata", error);
    }
  };

  useEffect(() => {
    fetchOptions("reference", setReferenceOptions);
    // fetchOptions("technology", setTechOptions);
  }, []);

  const [customerForm, setCustomerForm] = useState({
    firstname: "",
    lastname: "",
    email: "",
    mobileNumber: "",
    company: "",
    nextUpdate: "",
    lastContact: "",
    address: "",
  });

  const [requirementForm, setRequirementForm] = useState({
    technology: "",
    typeOfInstallation: "",
    location: "",
    pincode: "",
    //site condition
    avgBill: 0,
    siteCoordinates: "",
    numberOfFloors: 0,
    totalFlats: 0,
    roofTopArea: "",
    siteDescription: "",
    // solar
    solarCapacity: 0,
    solarBrand: "",
    solarType: "",
    // wind
    windCapacity: 0,
    windBrand: "",
    windType: "",
    // meter
    meterType: "",
    numberOfMeter: 0,
    consumerNumber: "",
    consumerType: "",
    sanctionedLoad: "",
    contractDemand: "",
    // inverter
    inverterCapacity: 0,
    connectionType: "",
    batteryBackupCapacity: "",
    batteryCapacity: "",
    batteryType: "",
    numberOfBatteries: "",
    systemVoltage: "",
    backupPower: "",
    // power
    projectStatus: "",
    product: "",
    accountOwner: "",
  });

  const handlePriorityChange = (event) => {
    setPriority(event.target.value);
  };

  const handleReferenceChange = (event) => {
    setReference(event.target.value);
  };

  const handleCustomerChange = (event) => {
    const { name, value } = event.target;
    setCustomerForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleRequirementChange = (event) => {
    const { name, value } = event.target;
    setRequirementForm((prev) => ({ ...prev, [name]: value }));
  };

  const submitCustomer = async () => {
    const numericRegex = /^(0|91)?[6-9][0-9]{9}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const {
      firstname,
      lastname,
      email,
      mobileNumber,
      company,
      nextUpdate,
      lastContact,
      address,
    } = customerForm;
    if (
      firstname === "" ||
      lastname === "" ||
      mobileNumber === "" ||
      email === "" ||
      address === ""
    ) {
      setError("Please fill all required fields");
    } else if (!numericRegex.test(mobileNumber)) {
      setError("Invalid Phone Number");
    } else if (!emailRegex.test(email)) {
      setError("Invalid Email Format");
    } else {
      const customerData = {
        firstname: firstname,
        lastname: lastname,
        email: email,
        mobileNumber: `+91${mobileNumber}`,
        company: company,
        nextUpdate: nextUpdate,
        lastContact: lastContact,
        reference: reference,
        password: "energybae@123",
      };
      try {
        const response = await registerCustomer(customerData);
        localStorage.setItem("customerId", response.user_id);
        setError(null);
        return true;
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          toast.error(error.response.data.message, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setError(error.response.data.message);
        } else {
          setError("An error occurred. Please try again later.");
        }
      }
    }
    return false;
  };

  const priorityOptions = ["High", "Medium", "Low"];

  const submitRequirement = async () => {
    const accessToken = localStorage.getItem("Authorization");
    const customerId = parseInt(localStorage.getItem("customerId"));
    if (!customerId) {
      setError("Please create a customer");
      return;
    }
    const {
      typeOfInstallation,
      location,
      pincode,
      avgBill,
      siteCoordinates,
      numberOfFloors,
      totalFlats,
      roofTopArea,
      siteDescription,
      solarCapacity,
      solarBrand,
      solarType,
      windCapacity,
      windBrand,
      windType,
      meterType,
      numberOfMeter,
      consumerType,
      sanctionedLoad,
      contractDemand,
      inverterCapacity,
      connectionType,
      batteryBackupCapacity,
      batteryCapacity,
      batteryType,
      numberOfBatteries,
      systemVoltage,
      backupPower,
      projectStatus,
      product,
      accountOwner,
      consumerNumber,
    } = requirementForm;

    const leadDetails = {
      customerId: parseInt(customerId),
      typeOfInstallation: typeOfInstallation,
      technology: selectedTechnology,
      location: location,
      pincode: pincode,
      avgBill: parseFloat(avgBill),
      siteCoordinates: siteCoordinates,
      numberOfFloors: parseInt(numberOfFloors),
      totalFlats: parseInt(totalFlats),
      roofTopArea: roofTopArea,
      siteDescription: siteDescription,
      solarCapacity: parseFloat(solarCapacity),
      solarBrand: solarBrand,
      solarType: solarType,
      windCapacity: parseFloat(windCapacity),
      windBrand: windBrand,
      windType: windType,
      meterType: meterType,
      numberOfMeter: parseInt(numberOfMeter),
      consumerType: consumerType,
      consumerNumber: parseInt(consumerNumber),
      sanctionedLoad: sanctionedLoad,
      contractDemand: contractDemand,
      inverterCapacity: parseFloat(inverterCapacity),
      connectionType: connectionType,
      batteryBackupCapacity: batteryBackupCapacity,
      batteryCapacity: batteryCapacity,
      batteryType: batteryType,
      numberOfBatteries: numberOfBatteries,
      systemVoltage: systemVoltage,
      backupPower: backupPower,
      projectStatus: projectStatus,
      product: product,
      accountOwner: accountOwner,
      priority: priority,
    };
    try {
      const response = await createLead(leadDetails, accessToken);
      localStorage.setItem("projectNo", response.data.projectNo);
      setIsLeadCreated(true); // Update state to indicate lead creation is successful
      localStorage.removeItem("customerId");
      setError(null);
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        setError(error.response.data.message);
      } else {
        setError("An error occurred. Please try again later.");
      }
    }
  };

  const handleSubmit = async () => {
    let customerRegistered;
    
    // if (!localStorage.getItem("customerId")) {
      customerRegistered = await submitCustomer();
    // }
    // else{
    //   customerRegistered = localStorage.getItem("customerId")
    // }
    if (customerRegistered) {
      await submitRequirement();
      toast.success("Lead Created Successfully", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      handleUpload(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const file = droppedFiles[0];
      handleUpload(file);
    }
  };

  const handleUpload = async (file) => {
    const accessToken = localStorage.getItem("Authorization");
    const projectNo = localStorage.getItem("projectNo");
    if (!projectNo) {
      setError("Please create a project");
      return;
    }

    setUploading(true);

    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("type", "site-media");
      formData.append("project_no", projectNo);

      const response = await uploadToStorage(formData, accessToken);
      if (localStorage.getItem("uploadedFiles")) {
        const uploadedFiles = JSON.parse(localStorage.getItem("uploadedFiles"));
        uploadedFiles.push(response.data.filename);
        localStorage.setItem("uploadedFiles", JSON.stringify(uploadedFiles));
        setUploadedFiles(uploadedFiles);
      } else {
        const uploadedFiles = [];
        uploadedFiles.push(response.data.filename);
        localStorage.setItem("uploadedFiles", JSON.stringify(uploadedFiles));
        setUploadedFiles(uploadedFiles);
      }
    } catch (error) {
      setError("Error uploading file");
    }

    setUploading(false);
  };

  const handleRemoveItem = async (indexToRemove) => {
    const accessToken = localStorage.getItem("Authorization");
    const response = await deleteFromStorage(
      uploadedFiles[indexToRemove],
      accessToken
    );

    const newUploadedFiles = uploadedFiles.filter(
      (_, index) => index !== indexToRemove
    );

    setUploadedFiles(newUploadedFiles);
    localStorage.setItem("uploadedFiles", JSON.stringify(newUploadedFiles));
  };

  const handleCreate = () => {
    localStorage.removeItem("customerId");
    localStorage.removeItem("projectNo");
    localStorage.removeItem("uploadedFiles");
    navigate("/all-leads");
  };

  const handleTechnology = (event) => {
    const value = event.target.value;
    setSelectedTechnology(value);

    if (selectedTechnology) {
      setSelectedTechnology(value);
      renderComponents();
    }
  };

  const typeOfInstallationOptions = [ "Industrial","Commercial", "Residential Society", "Residential Home"];
  
  const getTechnologyText = (value) => {
    const options = {
      "solar-on-grid": "solar-on-grid",
      "solar-off-grid": "solar-off-grid",
      "wind-on-grid": "wind-on-grid",
      "wind-off-grid": "wind-off-grid",
      "solar-wind-off-grid": "solar-wind-off-grid",
      "solar-wind-on-grid": "solar-wind-on-grid",
      "hybrid-on-off-grid-solar": "hybrid-on-off-grid-solar",
      "hybrid-on-off-grid-wind": "hybrid-on-off-grid-wind",
      "hybrid-on-off-grid-solar-wind": "hybrid-on-off-grid-solar-wind",
    };
  
    return options[value] || "";
  };
  
  const renderComponents = () => {
    switch (selectedTechnology) {
      case "solar-on-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "solar-off-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "wind-on-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "wind-off-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "solar-wind-off-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "solar-wind-on-grid":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "hybrid-on-off-grid-solar":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "hybrid-on-off-grid-wind":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      case "hybrid-on-off-grid-solar-wind":
        return (
          <>
            <SiteCondition
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <MeterDetails
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Inverter
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Solar
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Wind
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
            <Battery
              requirementForm={requirementForm}
              handleRequirementChange={handleRequirementChange}
            />
          </>
        );
      default:
        return null;
    }
  };
  
  const techOptions = [
    "solar-on-grid",
    "solar-off-grid",
    "wind-on-grid",
    "wind-off-grid",
    "solar-wind-off-grid",
    "solar-wind-on-grid",
    "hybrid-on-off-grid-solar",
    "hybrid-on-off-grid-wind",
    "hybrid-on-off-grid-solar-wind",
  ];
  

  useEffect(() => {
    const uploadedFilesString = localStorage.getItem("uploadedFiles");
    if (uploadedFilesString) {
      const uploadedFilesArray = JSON.parse(uploadedFilesString);
      setUploadedFiles(uploadedFilesArray);
    }
  }, []);

return (
  <div className="flex">   
      <div>
      <Header />
      <Sidebar />
      </div>

      <div className="mt-20">
        <Box sx={{ ml: 3, mr: 3 }}>
        <Box sx={{ ml: 3, mr: 3, p: 2 }}>
        <Card sx={{ p: 3 }}>
          <Typography variant="h5" component="div" className="m-2">
              Customer Details
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="First Name*"
                  id="firstname"
                  name="firstname"
                  onChange={handleCustomerChange}
                  value={customerForm.firstname}
                  placeholder="Enter first name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Last Name*"
                  id="lastname"
                  name="lastname"
                  value={customerForm.lastname}
                  onChange={handleCustomerChange}
                  placeholder="Enter last name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Email*"
                  id="email"
                  name="email"
                  value={customerForm.email}
                  onChange={handleCustomerChange}
                  placeholder="Enter email"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Phone*"
                  id="phone"
                  name="mobileNumber"
                  value={customerForm.mobileNumber}
                  onChange={handleCustomerChange}
                  placeholder="Enter phone"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Address*"
                  id="address"
                  name="address"
                  onChange={handleCustomerChange}
                  placeholder="Enter Address"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Company"
                  id="company"
                  name="company"
                  value={customerForm.company}
                  onChange={handleCustomerChange}
                  placeholder="Enter company name"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Next Update"
                  id="nextUpdate"
                  name="nextUpdate"
                  onChange={handleCustomerChange}
                  value={customerForm.nextUpdate}
                  type="date"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  label="Last Contact"
                  id="lastContact"
                  name="lastContact"
                  value={customerForm.lastContact}
                  onChange={handleCustomerChange}
                  type="date"
                  variant="outlined"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel htmlFor="reference">Reference</InputLabel>
                  <Select
                    id="reference"
                    name="ref"
                    value={reference || ""}
                    onChange={handleReferenceChange}
                    label="Reference"
                  >
                    <MenuItem value={null}>None</MenuItem>
                    {referenceOptions.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Card>

          <Card sx={{ p: 3, mt: 2 }}>
          <Typography variant="h5" component="div" className="m-2">
                Requirement
              </Typography>
              <Grid container spacing={1}>
              <Grid item xs={12} sm={3}>

                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="installation">Type of Installation</InputLabel>
                     <Select
                      id="installation"
                      name="typeOfInstallation"
                      value={requirementForm.typeOfInstallation || ""}
                      onChange={handleRequirementChange}
                      label="Type of Installation"
                    >
                       <MenuItem value="">None</MenuItem>
                    {typeOfInstallationOptions.map((item, index) => (
                      <MenuItem key={index} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                    </Select>
                  </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={3}>

                  <TextField
                    label="Location"
                    id="location"
                    name="location"
                    onChange={handleRequirementChange}
                    placeholder="Enter location"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                  <TextField
                    label="Pincode"
                    id="pincode"
                    name="pincode"
                    onChange={handleRequirementChange}
                    placeholder="Enter pincode"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                  />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel htmlFor="priority">Priority</InputLabel>
                    <Select
                      id="priority"
                      name="priority"
                      value={requirementForm.priority ?? ""}
                      onChange={handleRequirementChange}
                      label="Priority"
                    >
                      <MenuItem value="">None</MenuItem>
                      {priorityOptions.map((item, index) => (
                        <MenuItem key={index} value={item}>
                          {item}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  </Grid>
                  </Grid>
          </Card>

          <Card sx={{ p: 3, mt: 2 }}>
          <Typography variant="h5" component="div" className="m-2">
                Technology
              </Typography>
          <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
          <FormControl fullWidth margin="normal">
      <InputLabel htmlFor="technology">Technology</InputLabel>
      <Select
        id="technology"
        name="technology"
        value={selectedTechnology || ""}
        onChange={handleTechnology}
        label="Technology"
      >
        <MenuItem value={null}>Select Technology</MenuItem>
        {techOptions.map((item, index) => (
          <MenuItem key={index} value={item}>
            {getTechnologyText(item)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
              </Grid>
              </Grid>
              {renderComponents()}
          </Card>

          {/* <Card sx={{ p: 3 }}>
            <CardActions> */}
              <Button
                onClick={handleSubmit}
                variant="contained"
                color="success"
                className={`w-fit ml-4 ${isLeadCreated ? "opacity-50 cursor-not-allowed" : ""}`}
                disabled={isLeadCreated}
                sx={{ mt: 2, backgroundColor: "green", '&:hover': {
                backgroundColor: '#F0F0F0', color: 'black', fontWeight: 'bold'}, borderRadius: '7px', height: '40px', width: '120px', fontSize: '12px' }}>
                Create Lead
              </Button>
            {/* </CardActions>
          </Card> */}
          </Box>

          <Box sx={{ ml: 3, mr: 3, p: 2 }}>
          <Card sx={{ p: 3 }}>
              <Typography variant="h5" component="div" className="m-4">
                Attach Site Images and Videos
              </Typography>
              <div className="flex flex-col items-center mt-6">
                <div
                  className="border-2 border-dashed rounded-lg bg-gray-100 border-gray-400 p-10 text-center flex flex-col items-center w-4/5"
                  onDragOver={handleDragOver}
                  onDrop={handleDrop}
                >
                  <p className="text-lg">{uploading ? "Uploading..." : "Drag and drop file here"}</p>
                  <input
                    id="fileInput"
                    type="file"
                    onChange={handleFileChange}
                    multiple
                    className="hidden"
                  />
                  {!uploading && (
                    <Button
                      onClick={() => document.getElementById("fileInput").click()}
                      variant="outlined"
                      color="primary"
                    >
                      Upload
                    </Button>
                  )}
                </div>
              </div>
          </Card>

          <div className="w-3/5 mx-auto">
            {uploadedFiles.length > 0 &&
              uploadedFiles.map((item, index) => (
                <div key={index} className="flex justify-between items-center">
                  <p>
                    {index + 1}. {item.length > 50 ? `${item.slice(0, 50)}...` : item}
                  </p>
                  <img
                    src="./close.png"
                    alt="Remove"
                    className="cursor-pointer w-5"
                    onClick={() => handleRemoveItem(index)}
                  />
                </div>
              ))}
          </div>

          {/* <Card sx={{ p: 3 }}>
            <CardActions> */}
              <Button
                onClick={handleCreate}
                variant="contained"
                color="success"
                className="w-fit ml-4"
                sx={{ mt: 2, backgroundColor: "green", '&:hover': {
                  backgroundColor: '#F0F0F0', color: 'black', fontWeight: 'bold'}, borderRadius: '7px', height: '40px', width: '120px', fontSize: '12px' }}>
                Submit
              </Button>
              {error && <Typography color="error">{error}</Typography>}
            {/* </CardActions>
          </Card> */}
          </Box>
        </Box>

        </div>
    </div>
);
};

export default CreateLead;


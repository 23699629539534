import React, { useState, useEffect } from "react";
import axios from "axios";
import CustomTable from "../common/CustomTable";
import { Card, Modal, Box, Typography, IconButton, TextField, Button, MenuItem, Select } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Header from "../common/Header";
import Sidebar from "../common/Sidebar";
import DatasetIcon from '@mui/icons-material/Dataset';


const MetaData = (props) => {
  const [data, setData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');
  const [name, setName] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const options = [
    { value: "meter_connection_type", label: "Meter Connection Type" },
    { value: "meter_type", label: "Meter Type" },
    { value: "inverter_connection_type", label: "Inverter Connection Type" },
    { value: "solar_brand", label: "Solar Brand" },
    { value: "solar_module_type", label: "Solar Module Type" },
    { value: "wind_type", label: "Wind Type" },
    { value: "wind_brand", label: "Wind Turbine Brand" },
    { value: "battery_type", label: "Battery Type" },
    { value: "reference", label: "Reference" },
    { value: "technology", label: "Technology" },
    { value: "type_of_installation", label: "Type Of Installation" },
    { value: "account_owner", label: "Account Owner" },
  ];

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedDay}/${formattedMonth}/${year}`;
  };

  const openModal = () => {
    setSelectedOption('');
    setName('');
    setError(null);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOptions = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const fetchData = async () => {
    console.log(currentPage);
    const page = currentPage - 1;
    try {
      const accessToken = localStorage.getItem("Authorization");
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/metadata?page=${page}&size=${itemsPerPage}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      setData(response.data.content);
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!selectedOption || !name) {
        setError("Please fill all required fields");
        return;
      }
      const accessToken = localStorage.getItem("Authorization");
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/admin/metadata`,
        { name: name, type: selectedOption },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      if (response.status === 200) {
        closeModal();
        fetchData();
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [currentPage]);

  const Columns = [
    { Header: "Name", accessor: "name" },
    { Header: "Type", accessor: "type" },
    { Header: "Active", accessor: "active" },
    { Header: "Added By ID", accessor: "addedById" },
    { Header: "Added By Name", accessor: "addedByName" },
    { Header: "Added On", accessor: "addedOn" }
  ];

  return (
    <div className="flex h-full">
    <Header />
    <Sidebar />
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#074173' }}>
             Add New Data
            </Typography>
            <IconButton onClick={closeModal}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box component="form">
          <Box mb={2}>
          <Typography variant="body2" mb={1}>
            Type*
          </Typography>
          <Select
            value={selectedOption}
            onChange={handleOptions}
            fullWidth
            displayEmpty
            renderValue={(value) => (
              value === "" ? "Please Select Type" : options.find(option => option.value === value)?.label || ""
            )}
          >
            <MenuItem value="" disabled>
              Please Select Type
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </Box>
            <Box mb={2}>
              <Typography variant="body2" mb={1}>Name*</Typography>
              <TextField
                fullWidth
                variant="outlined"
                value={name}
                onChange={handleName}
                placeholder="Please Enter Name"
              />
            </Box>
            {error && <Typography variant="body2" color="error">{error}</Typography>}
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit}
              fullWidth
              sx={{ mt: 2 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
      <main className="flex-grow p-9 mt-11 overflow-auto">
        <Card>
        <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: '#074173' }}>
        <DatasetIcon sx={{ mr: 2 }} />
          Metadata
        </Typography>        
        <Button
            onClick={openModal}
            variant="contained"
            color="success"
            sx={{ mt: 2, ml: 2, backgroundColor: "#074173" }}
        >
          Add New
        </Button>
            <CustomTable columns={Columns} data={data}/>
          </Card>
        </main>
    </div>
 

  );
};

export default MetaData;

import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';

const CustomTable = ({ columns, data, onEdit, onOptionSelect, tableHeader, editable, buttons = [], filters = [], onFilterChange }) => {
  const [filteredData, setFilteredData] = useState([]);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  useEffect(() => {
    if (Array.isArray(data)) {
      setFilteredData(data);
    } else {
      setFilteredData([]);
    }
  }, [data]);

  const handleSearch = useCallback(() => {
    let filtered = data || [];

    if (search) {
      filtered = filtered.filter((row) =>
        Object.values(row).some(
          (value) =>
            value &&
            value.toString().toLowerCase().includes(search.toLowerCase())
        )
      );
    }

    setFilteredData(filtered);
  }, [data, search]);

  useEffect(() => {
    handleSearch();
  }, [search, handleSearch]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '84vh' }}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
        {/* <div style={{ marginRight: '416px' }}> */}
          {/* <Typography variant="h6" component="div" sx={{ flex: "1 1 100%" }}>
            {tableHeader}
          </Typography> */}
          {buttons.map((button, index) => (
            <Button
              key={index}
              onClick={button.onClick}
              variant="contained"
              size="medium"
              sx={button.sx}
            >
              {button.label}
            </Button>
          ))}
        {/* </div> */}
        {filters.map((filter, index) => (
          <Select
            key={index}
            value={filter.value}
            onChange={(e) => onFilterChange(filter.accessor, e.target.value)}
            // label={filter.label}
            sx={filter.sx}
            size="small"
          >
            {/* <MenuItem value="">All</MenuItem> */}
            {filter.options.map((option, i) => (
              <MenuItem key={i} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          
        ))}
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search..."
          onChange={(e) => setSearch(e.target.value)}
          value={search}
        />
      </Toolbar>
      <TableContainer sx={{ flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {editable === true && (
                <TableCell
                  sx={{
                    textAlign: "center",
                    fontWeight: "bold",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "background.paper",
                    zIndex: 1,
                  }}
                >
                  Action
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell
                  key={column.accessor}
                  className={column.className}
                  style={{
                    width: column.width,
                    fontWeight: "bold",
                    position: "sticky",
                    top: 0,
                    backgroundColor: "background.paper",
                    zIndex: 1,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {column.Header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? filteredData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              : filteredData
            ).map((row, index) => (
              <TableRow key={index}>
                {editable === true && (
                  <TableCell>
                    <Button
                      onClick={() => onEdit(row)}
                      color="primary"
                      sx={{ color: "black"}}
                    >
                      <EditIcon fontSize="small"/>
                    </Button>
                  </TableCell>
                )}
                {columns.map((column) => (
                  <TableCell
                    key={column.accessor}
                    className={column.className}
                    style={{ width: column.width }}
                  >
                    {column.editable ? (
                      column.type === "select" ? (
                        <Select
                          value={row[column.accessor]}
                          onChange={(e) =>
                            onOptionSelect(row, e.target.value, column.accessor)
                          }
                          sx={{ width: column.width || '120px', height: '30px', fontSize: '12px' }}
                        >
                          {column.options.map((option) => (
                            <MenuItem key={option.value} value={option.value} style={{ fontSize: '12px'}}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <TextField
                          value={row[column.accessor]}
                          onChange={(e) =>
                            onOptionSelect(row, e.target.value, column.accessor)
                          }
                          sx={{ width: column.width || '120px' }}
                        />
                      )
                    ) : column.Cell ? (
                      column.Cell({ value: row[column.accessor] })
                    ) : (
                      row[column.accessor]
                    )}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "end", mt: 2 }}>
        <TablePagination
          rowsPerPageOptions={[5, 10, 20, 100]}
          colSpan={columns.length + 1}
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Box>
  );
};

export default CustomTable;
